import React, { useEffect, useState } from "react";
import styles from "./NewDeck.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Modal from "../../components/Modal";
import Schedule from "../../components/Schedule";
import NameAndDescription from "./NameAndDescription";
import ImagesAndCTA from "./ImagesAndCTA";
import Price from "./Price";
import CategoryAndAttibutes from "./CategoryAndAttibutes";
import ProductFiles from "./ProductFiles";
import Discussion from "./Discussion";
import Preview from "./Preview";
import Panel from "./Panel";
import IncludedCards from "./IncludedCards";
import cn from "classnames";
import { AVAILABLE_LANGS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreateDeck, fetchEditDeck, fetchUpdateDeck, fetchUpdateDeckAvatar } from "../../app/decks/decks.slice";
import Loader from "../../components/Loader";
import { useHistory, useParams } from "react-router";
import useQuery from "../../hooks/useQuery";
import { parseSearchCardTags, parseSearchClassesTags } from "../../utils";
import IncludedClasses from "./IncludedClasses";
import Invitations from "./Invitations";

export const levelOptions = [
  { value: "Newbie", label: "Newbie" },
  { value: "Begginer", label: "Beginner" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Advanced", label: "Advanced" },
  { value: "Master", label: "Master" },
];

const NewDeck = ({ isNew = false }) => {
  const { user, decks } = useSelector((state) => state);

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [fileURL, setFileURL] = useState('')

  const [title, setTitle] = useState("");
  const [available, setAvailable] = useState(true);
  const [level, setLevel] = useState("Newbie");
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [description, setDescription] = useState();
  const [descriptionState, setDescriptionState] = useState();
  const [premiumActive, setPremiumActive] = useState(false)
  const [premiumPrice, setPremiumPrice] = useState('')
  const [selectedCards, setSelectedCards] = useState([]);
  const [avatarUrl, setAvatarUrl] = useState('')
  const [file, setFile] = useState('')
  const [classRooms, setClassRooms] = useState([]);
  const [invitations, setInvitations] = useState([])
  const [selectedLanguages, setSelectedLanguages] = useState(AVAILABLE_LANGS);
  const [languageInputs, setLanguageInputs] = useState({})
  const history = useHistory();
  const { deckId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (decks.editDeckValue && !isNew) {
      const editDeck = decks.editDeckValue;
      setTitle(editDeck.title);
      setAvailable(editDeck.available);
      setLevel(editDeck.level);
      setSelectedCards(editDeck.cards.map((cValue) => parseSearchCardTags(cValue)));
      setClassRooms(editDeck.classRooms ? editDeck.classRooms.map((cValue) => parseSearchClassesTags(cValue)) : []);
      setSelectedLanguages(editDeck.translations.filter((trans) => trans.active).map((trans) => trans.lang));
      setPremiumActive(editDeck.premium.active)
      setPremiumPrice(editDeck.premium.cost)
      setInvitations(editDeck.invitations)
      setAvatarUrl(editDeck.avatarUrl)
      setCategory(editDeck.category?._id || editDeck.category)
      setSubCategory(editDeck?.subCategory?._id || editDeck?.subCategory)

      let languageInputs_ = {};
      editDeck.translations.forEach((trans) => {
        languageInputs_[trans.lang] = trans.trans
      })
      setLanguageInputs(languageInputs_)
    }
  }, [decks.editDeckValue]);

  useEffect(() => {
    if(isNew){
      setTitle('');
      setAvailable(true);
      setLevel('Newbie');
      setSelectedCards([]);
      setClassRooms([]);
      setSelectedLanguages([]);
      setPremiumActive(false)
      setPremiumPrice('')
      setInvitations([])
      setAvatarUrl("")
      setLanguageInputs({})
    }
  }, [isNew])
  

  useEffect(() => {
    if (deckId) {
      dispatch(fetchEditDeck({ _id: deckId }));
    }
  }, [deckId]);

  console.log("AVAILABLE_LANGS", AVAILABLE_LANGS)

  const handleSave = async () => {
    const postData = {
      title,
      translations: AVAILABLE_LANGS.map((lang) => ({
        lang,
        trans: languageInputs?.[lang] || '',
        active: selectedLanguages.includes(lang),
      })),
      avatarUrl,
      classRooms: classRooms.map((classe) => classe.value), //Ids de las clases
      invitations, //Emails de los usuarios para invitiar
      cards: selectedCards.map((card) => card.value), //Ids de las cards
      available,
      new: true,
      category,
      subCategory,
      active: available,
      premium: {
        active: premiumActive,
        cost: premiumPrice,
      },
      level,
    };
    if (deckId) {
      postData._id = deckId;
      dispatch(fetchUpdateDeck(postData));
    } else {
      const createResponse = await dispatch(fetchCreateDeck(postData));
      console.log("createResponse", createResponse)
      if(createResponse?.payload?._id){
        handleUploadFile(file, createResponse?.payload?._id )
      }
    }
    history.push(`/`);
  };

  const handleUploadFile = (file, deckId) =>{
    let body = new FormData();
    body.append('file', file)
    body.append('deckId', deckId)
    dispatch(fetchUpdateDeckAvatar(body))
  }

  const handleSetFile = (e) =>{
    setFile(e?.target.files[0])

    setFileURL(URL.createObjectURL(e?.target.files[0]))
    
    if(!isNew){
      handleUploadFile(e?.target.files[0], deckId)
    }
  }

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription
            className={styles.card}
            title={title}
            setTitle={setTitle}
            available={available}
            setAvailable={setAvailable}
            level={level}
            user={user}
            setLevel={setLevel}
            descriptionState={descriptionState}
            setDescriptionState={setDescriptionState}
            description={description}
            setDescription={setDescription}
            categorySelected={category}
            setCategory={setCategory}
            subCategory={subCategory}
            setSubCategory={setSubCategory}
          />
          <IncludedCards deckId={deckId} className={styles.card} selectedCards={selectedCards} setSelectedCards={setSelectedCards} />
          <IncludedClasses className={styles.card} classRooms={classRooms} setClassRooms={setClassRooms} />
          <Invitations className={styles.card} invitations={invitations} setInvitations={setInvitations} />
          <CategoryAndAttibutes
            className={styles.card}
            selectedLanguages={selectedLanguages}
            setSelectedLanguages={setSelectedLanguages}
            languageInputs={languageInputs}
            setLanguageInputs={setLanguageInputs}
          />
          <ImagesAndCTA className={styles.card} handleUploadFile={handleSetFile} />
          <Price 
            className={styles.card} 
            premiumActive={premiumActive}
            setPremiumActive={setPremiumActive}
            premiumPrice={premiumPrice}
            setPremiumPrice={setPremiumPrice}
          />
          {/* <CategoryAndAttibutes className={styles.card} /> */}
          {/* <ProductFiles className={styles.card} />
          <Discussion className={styles.card} /> */}
        </div>
        <div className={styles.col}>
          <Preview visible={visiblePreview} onClose={() => setVisiblePreview(false)} avatarUrl={avatarUrl} title={title} file={fileURL} />
        </div>
      </div>
      <div className={cn("button", styles.button)} onClick={handleSave} >
        {decks.isLoadingCreate ? <Loader className={styles.loader} /> : <span>Save changes</span>}
      </div>

      {/* <Panel setVisiblePreview={setVisiblePreview} setVisibleSchedule={setVisibleModal} /> */}
      {/* <TooltipGlodal />
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <Schedule startDate={startDate} setStartDate={setStartDate} startTime={startTime} setStartTime={setStartTime} />
      </Modal> */}
    </>
  );
};

export default NewDeck;
