import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillSound } from 'react-icons/ai'
import styles from "./Cards.module.sass";
import TableComponent from '../../components/TableComponent';
import { fetchCards } from '../../app/cards/cards.slice';
import { toast } from 'sonner'
import Card from '../../components/Card';
import CardPreview from '../../components/CardPreview';
import { useHistory } from "react-router";
import { BiUpload } from 'react-icons/bi';
import Modal from '../../components/Modal';
import File from '../../components/File';
import classNames from 'classnames';
import Loader from '../../components/Loader';
import { removeCard, uploadBulkCard, uploadVideosCard } from '../../api/cards.service';
import AddBugModal from '../BugsReports/components/AddBugModal';
import { parseSearchCardTags } from '../../utils';
import DropdownSearch from '../../components/DropdownSearch';
import { fetchDecks } from '../../app/decks/decks.slice';


const columns = [
    {
      header: 'Char',
      accessor: 'char'
    },
    {
      header: 'Pinyin',
      accessor: 'pinyin'
    },
    {
      header: 'Translation',
      accessor: 'translation'
    },
    {
      header: 'Actions',
      accessor: 'actions'
    },
]

const Cards = () => {

    const dispatch = useDispatch()
    const { user, decks, cards } = useSelector((state) => state)

    const [data, setData] = useState([])

    const [visibleModalAddBug, setVisibleModalAddBug] = useState(false)
    const [cardSelectedForReport, setcardSelectedForReport] = useState('')

    const [cardSelected, setCardSelected] = useState('')

    const [isModalBulkVideo, setIsModalBulkVideo] = useState(false)
    const [isModalUploadCSV, setIsModalUploadCSV] = useState(false)

    const [deckSelected, setDeckSelected] = useState('')

    const [csv, setCsv] = useState('')

    const [videos, setVideos] = useState([])

    const [loadingUpload, setLoadingUpload] = useState(false)
    const [errors, setErrors] = useState([])

    const [nextPageHash, setNextPageHash] = useState('')

    const [isDeleteModal, setIsDeleteModal] = useState(false)

    const history = useHistory();
    
    const handleTextToSpeech = (text) =>{
        var msg = new SpeechSynthesisUtterance();
        msg.text = text;
        msg.lang = 'zh';
        window.speechSynthesis.speak(msg);
    }

    const handleUploadCSV = (e) =>{
      console.log("e", e.target.files?.[0])
      if(e.target.files?.[0]?.type?.includes('csv')){
        setCsv(e.target.files?.[0])
      }else{
        toast.error('Only .csv file')
      }
    }

    const handleUploadFile = (e) =>{
      console.log("e", e.target.files)
      setVideos([...e.target.files])
    }

    const handleRemoveIndex = (index) =>{
      setVideos(videos => videos.filter((c, i) => i !== index))
    }

    const handleUploadVideos = async () =>{
      setLoadingUpload(true)
      let count = 0
      async function uploadFile(file){
        let body = new FormData();
        body.append('file', file)
        let uploaded = await uploadVideosCard(body)
        console.log("uploaded", uploaded)
        if(uploaded?.data === true){
          setErrors(errors => [...errors, {status: true}])
        }else{
          setErrors(errors => [...errors, {status: false, message: uploaded?.response?.data?.message}])
        }

        if(videos?.[count+1]){
          count = count + 1
          uploadFile(videos?.[count])
        }
      }
      if(videos?.length > 0){
        uploadFile(videos?.[0])
      }
    }

    const handleRemoveVideos = () =>{
      setVideos([])
      setErrors([])
    }

    useEffect(() => {
      if(errors?.length === videos?.length){
        setLoadingUpload(false)
      }
    }, [errors, videos])
    

    useEffect(() => {
      handleSearchCard('')
    }, [])

    const handleDeleteCard = async () =>{
      let response = await removeCard(cardSelected?._id)
      if(response?.data === true){
        toast.success('Card deleted succesfully')
        dispatch(
          fetchCards({
            take: 100,
            skip: 0,
          })
        );

        setTimeout(() => {
          setIsDeleteModal(false)
        }, 1500);
      }else{
        toast.error('An error ocurred.')
      }
    }
    

    const handleLoadMoreCards = async () =>{
      // console.log("hola hay mas cartas")
      // const cards_ = await dispatch(fetchCards({
      //   take: 20,
      //   nextPageHash,
      //   //search: "a",
      // }))
      // console.log("cards_", cards_)
      // setData(data => [
      //   ...data, 
      //   ...cards_?.payload?.results?.map((card)=>{
      //         return{
      //             char: {
      //               data: card?.meaning?.character,
      //               dataComponent: card?.meaning?.character
      //             },
      //             pinyin: {
      //               data: card?.meaning?.pinyin,
      //               dataComponent: card?.meaning?.pinyin
      //             },
      //             translation: {
      //               data: card?.meaning?.trans,
      //               dataComponent: card?.meaning?.trans
      //             },
      //             actions: {
      //               data: 'actions',
      //               dataComponent: (
      //                 <div style={{ display: "flex", alignItems: "center"}}>
      //                     <button onClick={()=>{
      //                       setcardSelectedForReport(card)
      //                       setVisibleModalAddBug(true)
      //                     }} style={{color: 'red'}}>
      //                     Report
      //                     </button>
      //                     <button onClick={()=>setCardSelected(card)} style={{color: 'blue', marginLeft: 20}}>
      //                       View
      //                     </button>
      //                     {user?.role !== 'TEACHER' &&
      //                         <button onClick={()=>history.push(`/card/edit/${card?._id}`)} style={{color: 'blue', marginLeft: 20}}>
      //                             Edit
      //                         </button>
      //                     }
      //                 </div>
      //               )
      //             },
      //         }
      //     })
      // ])
      // setNextPageHash(cards_?.payload?.pagination?.nextPageHash)
    }

    useEffect(() => {
      console.log("cards loaded -> ", cards)
      setData(data => cards?.value?.map((card)=>{
              return{
                  char: {
                    data: card?.meaning?.character,
                    dataComponent: <div className={styles.pointer} onClick={()=>setCardSelected(card)}>{card?.meaning?.character}</div>
                  },
                  pinyin: {
                    data: card?.meaning?.pinyin,
                    dataComponent: <div className={styles.pointer} onClick={()=>setCardSelected(card)}>{card?.meaning?.pinyin}</div>
                  },
                  translation: {
                    data: card?.meaning?.trans,
                    dataComponent: <div className={styles.pointer} onClick={()=>setCardSelected(card)}>{card?.meaning?.trans}</div>
                  },
                  actions: {
                    data: 'actions',
                    dataComponent: (
                      <div style={{ display: "flex", alignItems: "center"}}>
                          <button onClick={()=>{
                            setcardSelectedForReport(card)
                            setVisibleModalAddBug(true)
                          }} style={{color: 'red'}}>
                          Report
                          </button>
                          {user?.value?.role !== 'TEACHER' &&
                              <button onClick={()=>history.push(`/card/edit/${card?._id}`)} style={{color: 'blue', marginLeft: 20}}>
                                  Edit
                              </button>
                          }
                          {user?.value?.role !== 'TEACHER' &&
                              <button onClick={()=>{
                                setIsDeleteModal(true)
                                setCardSelected(card)
                              }} style={{color: 'red', marginLeft: 20}}>
                                  Delete
                              </button>
                          }
                      </div>
                    )
                  },
              }
          })
      )
    }, [cards])
    


    const handleSearchCard = (e) =>{
      dispatch(
        fetchCards({
          take: 100,
          skip: 0,
          search: e,
        })
      );
    }

    const handleUploadBulkCards = async () => {
      setLoadingUpload(true)
      let body = new FormData();
      body.append('file', csv)
      body.append('deck', deckSelected?.value)
      let uploaded = await uploadBulkCard(body)
      console.log('uploaded', uploaded)
      if(uploaded?.status === 200){
        setLoadingUpload(false)
        toast.success('Cards uploaded successfully')
        var blob = new Blob([uploaded?.data], {type: "text/csv"});
        var objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
        setCsv('')
        setIsModalUploadCSV(false)
      }else{
        setLoadingUpload(false)
        toast.error('Error on uploading cards')
      }
    }

    const handleSearchDeck = (e) =>{
      dispatch(
        fetchDecks({
          take: 100,
          skip: 0,
          search: e,
        })
      );
    }

    const handleSetValueDeck = (e) =>{
      setDeckSelected(e)
  }

    return (
      <div style={{width: '100%'}}>
        <AddBugModal visible={visibleModalAddBug} setVisible={setVisibleModalAddBug} selectedCardProp={cardSelectedForReport} />
        <Modal outerClassName={styles.outer} visible={isModalUploadCSV} onClose={() => setIsModalUploadCSV(false)} >
          <div style={{marginTop: 20}}>
            <div style={{paddingBottom: 20}}>
                <div className={styles.label}>
                    Select deck
                </div>
                <DropdownSearch
                    className={styles.field}
                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    value={deckSelected?.value}
                    placeholder="Search Decks"
                    setValue={handleSetValueDeck}
                    options={decks.value.map((deck) => {
                      return{
                        label: deck?.title,
                        value: deck?._id
                      }
                    } )}
                    isLoading={decks.isLoading}
                    timeoutSearch={500}
                    onSearch={handleSearchDeck}
                />
                <div style={{ marginTop: 20 }}>
                    <div style={{fontSize: 14}}>
                        Deck Selected
                    </div>
                    <div style={{fontSize: 16, marginTop: 10}}>
                        {deckSelected?.label || 'none'}
                    </div>
                </div>
            </div>
            <File
                className={styles.field}
                onChange={handleUploadCSV}
                title="Click or drop .CSV file"
                label="Upload CSV"
            />
            <div className={styles.inputsField}>
              {csv &&
                <div style={{ backgroundColor: '#F4F4F4', borderRadius: '12px', padding: '12px' }}>
                    <div>
                      {csv?.name}
                    </div>
                    <div style={{fontSize: 12, color: 'gray'}}>
                      {csv?.type}
                    </div>
                </div>
              }
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
              <button disabled={!csv} style={{marginLeft: 20}} className={classNames("button", styles.button)} onClick={handleUploadBulkCards} >
                  {loadingUpload ? <Loader white className={styles.loader} /> : <span>Upload CSV</span>}
              </button>
              <button disabled={!csv} style={{marginLeft: 20}} className={classNames("button-stroke-red", styles.button)} onClick={()=>setCsv('')} >
                  Remove CSV
              </button>
            </div>
          </div>
        </Modal>
        <Modal visible={isDeleteModal} onClose={() => setIsDeleteModal(false)}>
            <div>
                <div style={{fontSize: 16, fontWeight: 'bold', marginTop: 10}}>
                    Delete card
                </div>
                <div style={{ fontSize: 14, color: 'gray', marginTop: 10}}>
                    Are you sure you want to delete {cardSelected?.meaning?.character} ({cardSelected?.meaning?.pinyin})?
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
                    <button style={{marginLeft: 20}} className={classNames("button", styles.button)} onClick={()=>setIsDeleteModal(false)} >
                        Cancel
                    </button>
                    <button style={{marginLeft: 20}} className={classNames("button-stroke-red", styles.button)} onClick={handleDeleteCard} >
                        Delete card
                    </button>
                </div>
            </div>
        </Modal>
        <Modal outerClassName={styles.outer} visible={isModalBulkVideo} onClose={() => setIsModalBulkVideo(false)} >
          <div style={{marginTop: 10}}>
            <File
                className={styles.field}
                onChange={handleUploadFile}
                title="Click or drop video"
                label="Upload video"
                tooltip="Maximum 100 characters. No HTML or emoji allowed"
            />
            <div className={styles.inputsField}>
              {videos?.map((c, i)=>(
                <div key={i} style={{ backgroundColor: '#F4F4F4', borderRadius: '12px', padding: '12px', display: 'flex', position: 'relative' }}>
                    <video src={URL.createObjectURL(c)} style={{ width: '150px', borderRadius: '12px'}} controls />
                    <div style={{ marginLeft: 20, fontSize: 14 }}>
                        {c?.name}
                        {errors?.[i]?.status === false &&
                          <small style={{ marginLeft: 20, color: 'red'}}>
                            Error ({errors?.[i]?.message || 'Network Error'})
                          </small>
                        }
                        {errors?.[i]?.status === true &&
                          <small style={{ marginLeft: 20, color: 'green'}}>
                            Success
                          </small>
                        }
                    </div>
                    {(!errors?.[i]?.status === true || !errors?.[i]?.status === false) &&
                      <button style={{color: 'red', position: "absolute", top: '40%', right: 20}} onClick={()=>handleRemoveIndex(i)} >
                          Delete
                      </button>
                    }
                </div>
              ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
              {errors?.filter(c => c?.status === false)?.length > 0 &&
                <span style={{ marginRight: 20, color: 'red', marginTop: 10}}>
                  {errors?.filter(c => c?.status === false)?.length} Errors
                </span>
              }
              <button disabled={videos.length === 0 || loadingUpload} style={{marginLeft: 20}} className={classNames("button", styles.button)} onClick={handleUploadVideos} >
                  {loadingUpload ? (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <Loader className={styles.loader} white />
                      <span>Uploading... ({errors?.length}/{videos?.length} videos)</span>
                    </div>
                    ) : <span>Upload ({videos?.length} videos)</span>}
              </button>
              <button style={{marginLeft: 20}} className={classNames("button-stroke-red", styles.button)} onClick={handleRemoveVideos} >
                  Remove Videos
              </button>
            </div>
          </div>
        </Modal>
        <div className={styles.row}>
            <div className={styles.col}>
                <TableComponent 
                    data={data}
                    columns={columns}
                    title="Cards"
                    handleSearchAPI={handleSearchCard}
                    searchPlaceholder="Search card..."
                    className="w-full"
                    isButton={user?.value?.role !== 'TEACHER'}
                    buttonText="Upload bulk videos"
                    buttonIcon={<BiUpload />}
                    onPageLoad={handleLoadMoreCards}
                    buttonHandler={()=>setIsModalBulkVideo(true)}
                    contentRight={user?.value?.role === 'ADMIN' && <button onClick={()=>setIsModalUploadCSV(true)} className={classNames("button-stroke", styles.button)}>
                      Upload Bulk Cards
                    </button>}
                />
            </div>
            <div className={styles.col}>
                <CardPreview card={cardSelected} />
            </div>
        </div>
      </div>
    );
}

export default Cards;