import React, { useState } from "react";
import styles from "./NameAndInfo.module.sass";
import Card from "../../../../../components/Card";
import Switch from "../../../../../components/Switch";
import cn from "classnames";
import TextInput from "../../../../../components/TextInput";
import Dropdown from "../../../../../components/Dropdown";
import { toast } from "sonner";

const LANGS = ["EN", "ES", "FR", "RU", "PT"];

export const levelOptions = [
  { value: "Newbie", label: "Newbie" },
  { value: "Begginer", label: "Beginner" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Advanced", label: "Advanced" },
  { value: "Master", label: "Master" },
];

const NameAndInfo = ({
  objectTitle,
  setObjectTitle,
  objectTextarea,
  setObjectTextarea,
  textAvailability,
  setTextAvailability,
  categoryOptions,
  setCategoryOptions,
  subCategoryOptions,
  setSubCategoryOptions,
  categorySelected,
  setCategorySelected,
  subCategorySelected,
  setSubCategorySelected,
  levelSelected,
  setLevelSelected,
}) => {
  const [activeTitleTab, setActiveTitleTab] = useState(LANGS[0]);
  const [activeContentTab, setActiveContentTab] = useState(LANGS[0]);

  const [loadingTranslationTitle, setLoadingTranslationTitle] = useState(false);
  const [loadingTranslationOptions, setLoadingTranslationOptions] = useState(false);

  const handleTranslateTitle = async () => {
    setLoadingTranslationTitle(true);
    const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
    const authorizationHeader = { Authorization: `Bearer ${JSON.parse(localStorage?.getItem("user")).token}` }; // Crea el encabezado Authorization

    let TRANS_LANGS = ["es", "pt", "fr", "ru"];

    let objectTitle_ = objectTitle;

    for (let lang_ of TRANS_LANGS) {
      let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          title: objectTitle_["EN"],
          lang: lang_,
        }),
        headers: {
          "Content-Type": "application/json",
          ...authorizationHeader, // Agrega el encabezado Authorization a los encabezados de solicitud
        },
      });
      let response2 = await response.json();
      let translation = response2?.translations?.[0]?.text;
      objectTitle_[lang_.toUpperCase()] = translation;
    }

    toast.success("Translated succesfully");
    setLoadingTranslationTitle(false);
    setObjectTitle(objectTitle_);
  };

  const handleTranslateDescription = async () => {
    setLoadingTranslationOptions(true);
    const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
    const authorizationHeader = { Authorization: `Bearer ${JSON.parse(localStorage?.getItem("user")).token}` }; // Crea el encabezado Authorization

    let TRANS_LANGS = ["es", "pt", "fr", "ru"];

    let objectTitle_ = objectTextarea;

    for (let lang_ of TRANS_LANGS) {
      let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          title: objectTitle_["EN"],
          lang: lang_,
        }),
        headers: {
          "Content-Type": "application/json",
          ...authorizationHeader, // Agrega el encabezado Authorization a los encabezados de solicitud
        },
      });
      let response2 = await response.json();
      let translation = response2?.translations?.[0]?.text;
      objectTitle_[lang_.toUpperCase()] = translation;
    }

    toast.success("Translated succesfully");
    setLoadingTranslationOptions(false);
    setObjectTextarea(objectTitle_);
  };

  return (
    <Card title="Name & Info" classTitle="title-green">
      <div className={styles.line}>
        <div className={styles.info}>Text availability</div>
        <Switch
          className={styles.switch}
          value={textAvailability}
          onChange={() => setTextAvailability(!textAvailability)}
        />
      </div>
      <div>
        <label style={{ fontSize: 14, display: "flex", alignItems: "center" }}>
          <span>Text title</span>
          <button
            onClick={() => handleTranslateTitle()}
            style={{ marginLeft: 20 }}
            className={cn("button-stroke button-small", styles.button)}
          >
            {loadingTranslationTitle ? (
              <>
                <svg width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                      <stop stop-color="#000" stop-opacity="0" offset="0%" />
                      <stop stop-color="#000" stop-opacity=".631" offset="63.146%" />
                      <stop stop-color="#000" offset="100%" />
                    </linearGradient>
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                    <g transform="translate(1 1)">
                      <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="0.9s"
                          repeatCount="indefinite"
                        />
                      </path>
                      <circle fill="#000" cx="36" cy="18" r="1">
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="0.9s"
                          repeatCount="indefinite"
                        />
                      </circle>
                    </g>
                  </g>
                </svg>
              </>
            ) : (
              <span>Auto-translate</span>
            )}
          </button>
        </label>
        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }} className={cn(styles.nav, "tablet-hide")}>
          {LANGS.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeTitleTab,
              })}
              onClick={() => setActiveTitleTab(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        <div style={{ marginTop: 10 }}>
          <TextInput
            type="text"
            required
            placeholder="Text title here"
            value={objectTitle?.[activeTitleTab] || ""}
            onChange={(e) =>
              setObjectTitle((objectTitle) => {
                return { ...objectTitle, [activeTitleTab]: e.target.value };
              })
            }
          />
        </div>
      </div>
      <div style={{ marginTop: 25 }}>
        <label style={{ fontSize: 14, display: "flex", alignItems: "center" }}>
          <span>Short description</span>
          <button
            onClick={() => handleTranslateDescription()}
            style={{ marginLeft: 20 }}
            className={cn("button-stroke button-small", styles.button)}
          >
            {loadingTranslationOptions ? (
              <>
                <svg width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                      <stop stop-color="#000" stop-opacity="0" offset="0%" />
                      <stop stop-color="#000" stop-opacity=".631" offset="63.146%" />
                      <stop stop-color="#000" offset="100%" />
                    </linearGradient>
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                    <g transform="translate(1 1)">
                      <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="0.9s"
                          repeatCount="indefinite"
                        />
                      </path>
                      <circle fill="#000" cx="36" cy="18" r="1">
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="0.9s"
                          repeatCount="indefinite"
                        />
                      </circle>
                    </g>
                  </g>
                </svg>
              </>
            ) : (
              <span>Auto-translate</span>
            )}
          </button>
        </label>
        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }} className={cn(styles.nav, "tablet-hide")}>
          {LANGS.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeContentTab,
              })}
              onClick={() => setActiveContentTab(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        <div style={{ marginTop: 10 }}>
          <textarea
            rows="30"
            style={{ height: "200px" }}
            className={styles.input}
            placeholder="Write here..."
            value={objectTextarea?.[activeContentTab] || ""}
            onChange={(e) =>
              setObjectTextarea((objectTextarea) => {
                return { ...objectTextarea, [activeContentTab]: e.target.value };
              })
            }
          ></textarea>
        </div>
      </div>
      <div style={{ marginTop: 25 }}>
        <Dropdown
          className={styles.field}
          classDropdownLabel={styles.label}
          value={categorySelected}
          setValue={setCategorySelected}
          options={categoryOptions}
          label="Master category"
        />
      </div>
      <div style={{ marginTop: 25 }}>
        <Dropdown
          className={styles.field}
          classDropdownLabel={styles.label}
          value={subCategorySelected}
          setValue={setSubCategorySelected}
          options={subCategoryOptions}
          label="Category"
        />
      </div>
      <div style={{ marginTop: 25 }}>
        <Dropdown
          className={styles.field}
          classDropdownLabel={styles.label}
          value={levelSelected}
          setValue={setLevelSelected}
          options={levelOptions}
          label="Level"
        />
      </div>
    </Card>
  );
};

export default NameAndInfo;
