import React, { useEffect, useState } from 'react'
import styles from "./Categories.module.sass";
import Card from '../../components/Card';
import Dropdown from '../../components/Dropdown';
import classNames from 'classnames';
import { useHistory } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { BiPlus } from 'react-icons/bi';
import Modal from '../../components/Modal';
import { fetchDecks, fetchUpdateDeck } from '../../app/decks/decks.slice';
import DropdownSearch from '../../components/DropdownSearch';
import { toast } from 'sonner';
import Actions from '../../components/Actions';
import TextInput from '../../components/TextInput';
import { updateCategory } from '../../api/category.service';
import { FiRepeat } from 'react-icons/fi';

const intervals = [{
    label: 'Order by: Plays',
    value: 0
}, {
    label: 'Order by: Words',
    value: 1
}, {
    label: 'Order by: Decks',
    value: 2
}];

const navigation = ["Available", "Disabled", "Coming soon"];



const Categories = () => {

    const dispatch = useDispatch()

    const { decks, category } = useSelector((state) => state);

    const [categories, setCategories] = useState([])

    const [activeIndex, setActiveIndex] = useState("Available");
    const [sorting, setSorting] = useState(intervals[0]);

    const [categoryIdSelected, setCategoryIdSelected] = useState('')
    const [isVisibleModalAddDeck, setIsVisibleModalAddDeck] = useState(false)
    const [deckSelected, setDeckSelected] = useState('')

    const [isVisibleModalDeleteDeck, setIsVisibleModalDeleteDeck] = useState(false)

    const [isVisibleModalEditCategory, setIsVisibleModalEditCategory] = useState(false)

    const history = useHistory();

    useEffect(() => {
        if(category){
            console.log("category", category)
            setCategories(category.value)
        }
    }, [category])
    
    const handleAddSort = (e) =>{
        setSorting({ value: e })
    }

    const handleSetValueDeck = (e) =>{
        setDeckSelected(e)
    }

    const handleSearchDeck = (e) =>{
        dispatch(
          fetchDecks({
            take: 100,
            skip: 0,
            search: e,
          })
        );
      }

    const handleShowAddDeck = (category) =>{
        setCategoryIdSelected(category)
        setIsVisibleModalAddDeck(true)
    }

    const handleShowEditCategory = (category) =>{
        setCategoryIdSelected(category)
        setIsVisibleModalEditCategory(true)
    }

    const handleAddDeck = async () =>{
        console.log("deckSelected", deckSelected)
        let json = {
            _id: deckSelected?.value,
            title: deckSelected?.label,
            category: categoryIdSelected?._id
        }
        let result = await dispatch(fetchUpdateDeck(json));
        if(result){
            dispatch(fetchDecks())
            toast.success('Deck added successfully')
            setIsVisibleModalAddDeck(false)
        }else{
            toast.error(result?.message)
        }
    }

    const handleDeleteDeck = async () =>{
        console.log("deckSelected", deckSelected)
        let json = {
            _id: deckSelected?._id,
            title: deckSelected?.title,
            category: ''
        }
        let result = await dispatch(fetchUpdateDeck(json));
        console.log("result", result)
        if(result){
            dispatch(fetchDecks())
            toast.success('Deck removed successfully')
            setIsVisibleModalDeleteDeck(false)
        }else{
            toast.error(result?.data?.message)
        }
    }

    const handleEditCategory = async () =>{
        console.log("categoryIdSelected", categoryIdSelected)
        let json = {
            _id: categoryIdSelected?._id,
            translations: categoryIdSelected?.translations
        }
        let result = await updateCategory(json)
        console.log("result", result)
        if(result?.data?._id){
            dispatch(fetchDecks())
            toast.success('Category updated successfully')
            setIsVisibleModalEditCategory(false)
        }else{
            toast.error(result?.data?.message)
        }
    }


    const handleTrad = (lang) =>{
        const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
        const authorizationHeader = { 'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).token}` }; // Crea el encabezado Authorization
    
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                title: categoryIdSelected?.translations?.filter(c => c?.lang === 'en')?.[0]?.trans,
                lang
            }),
            headers: {
                'Content-Type': 'application/json',
                ...authorizationHeader // Agrega el encabezado Authorization a los encabezados de solicitud
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la respuesta de la API');
            }
            return response.json();
        })
        .then(data => {
            console.log('Respuesta de la API:', data);
            let translation = data?.translations?.[0]?.text
            if(translation){
                setCategoryIdSelected({
                    ...categoryIdSelected,
                    translations: [...categoryIdSelected?.translations?.filter(c => c?.lang !== lang?.toLowerCase()), {
                        lang: lang?.toLowerCase(),
                        trans: translation
                    }]
                })
            }
        })
        .catch(error => {
            console.error('Error al llamar a la API:', error);
        });
    }
    

    return (
        <Card>
            <Modal outerClassName={styles.outer} visible={isVisibleModalEditCategory} onClose={() => {
                setDeckSelected('')
                setIsVisibleModalEditCategory(false)
            }} >
                <div style={{fontSize: 20}}>
                    Edit Category <b>{categoryIdSelected?.title || 'untitled'}</b>
                </div>
                <div style={{marginTop: 20}}>
                    <div style={{paddingBottom: 20}}>
                        
                        <div style={{ marginTop: 20, position: 'relative' }}>
                            <TextInput
                                name="Tone"
                                type="text"
                                tooltip="Small description"
                                required
                                currency="EN"
                                value={categoryIdSelected?.translations?.filter(c => c?.lang === 'en')?.[0]?.trans}
                                onChange={(e)=>{
                                    setCategoryIdSelected({
                                        ...categoryIdSelected,
                                        translations: [...categoryIdSelected?.translations?.filter(c => c?.lang !== 'en'), {
                                            lang: "en",
                                            trans: e.target.value
                                        }]
                                    })
                                }}
                            />
                        </div>
                        <div style={{ marginTop: 10, position: 'relative' }}>
                            <TextInput
                                name="Tone"
                                type="text"
                                tooltip="Small description"
                                required
                                currency="ES"
                                value={categoryIdSelected?.translations?.filter(c => c?.lang === 'es')?.[0]?.trans}
                                onChange={(e)=>{
                                    setCategoryIdSelected({
                                        ...categoryIdSelected,
                                        translations: [...categoryIdSelected?.translations?.filter(c => c?.lang !== 'es'), {
                                            lang: "es",
                                            trans: e.target.value
                                        }]
                                    })
                                }}
                            />
                            <div style={{ position: 'absolute', right: 20, top:0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <button onClick={()=>handleTrad('ES')} style={{ marginRight: 0 }}>
                                    <FiRepeat size="20" />
                                </button>
                            </div>
                        </div>
                        <div style={{ marginTop: 10, position: 'relative' }}>
                            <TextInput
                                name="Tone"
                                type="text"
                                tooltip="Small description"
                                required
                                currency="FR"
                                value={categoryIdSelected?.translations?.filter(c => c?.lang === 'fr')?.[0]?.trans}
                                onChange={(e)=>{
                                    setCategoryIdSelected({
                                        ...categoryIdSelected,
                                        translations: [...categoryIdSelected?.translations?.filter(c => c?.lang !== 'fr'), {
                                            lang: "fr",
                                            trans: e.target.value
                                        }]
                                    })
                                }}
                            />
                            <div style={{ position: 'absolute', right: 20, top:0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <button onClick={()=>handleTrad('FR')} style={{ marginRight: 0 }}>
                                    <FiRepeat size="20" />
                                </button>
                            </div>
                        </div>
                        <div style={{ marginTop: 10, position: 'relative' }}>
                            <TextInput
                                name="Tone"
                                type="text"
                                tooltip="Small description"
                                required
                                currency="RU"
                                value={categoryIdSelected?.translations?.filter(c => c?.lang === 'ru')?.[0]?.trans}
                                onChange={(e)=>{
                                    setCategoryIdSelected({
                                        ...categoryIdSelected,
                                        translations: [...categoryIdSelected?.translations?.filter(c => c?.lang !== 'ru'), {
                                            lang: "ru",
                                            trans: e.target.value
                                        }]
                                    })
                                }}
                            />
                            <div style={{ position: 'absolute', right: 20, top:0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <button onClick={()=>handleTrad('RU')} style={{ marginRight: 0 }}>
                                    <FiRepeat size="20" />
                                </button>
                            </div>
                        </div>
                        <div style={{ marginTop: 10, position: 'relative' }}>
                            <TextInput
                                name="Tone"
                                type="text"
                                tooltip="Small description"
                                required
                                currency="PT"
                                value={categoryIdSelected?.translations?.filter(c => c?.lang === 'pt')?.[0]?.trans}
                                onChange={(e)=>{
                                    setCategoryIdSelected({
                                        ...categoryIdSelected,
                                        translations: [...categoryIdSelected?.translations?.filter(c => c?.lang !== 'pt'), {
                                            lang: "pt",
                                            trans: e.target.value
                                        }]
                                    })
                                }}
                            />
                            <div style={{ position: 'absolute', right: 20, top:0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <button onClick={()=>handleTrad('PT')} style={{ marginRight: 0 }}>
                                    <FiRepeat size="20" />
                                </button>
                            </div>
                        </div>
                        <button onClick={()=>handleEditCategory()} style={{width: '100%', marginTop: 30}} className={classNames("button-small", styles.button)}>
                            Update
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal outerClassName={styles.outer} visible={isVisibleModalDeleteDeck} onClose={() => {
                setDeckSelected('')
                setIsVisibleModalDeleteDeck(false)
            }} >
                <div style={{fontSize: 20}}>
                    Remove deck <b>{deckSelected?.title}</b> from <b> {categoryIdSelected?.title || 'untitled'}</b>
                </div>
                <div style={{marginTop: 20}}>
                    <div style={{paddingBottom: 20}}>
                        <button disabled={!deckSelected?.title} onClick={()=>handleDeleteDeck()} style={{width: '100%', marginTop: 30}} className={classNames("button-stroke-red", styles.button)}>
                            Remove deck
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal outerClassName={styles.outer} visible={isVisibleModalAddDeck} onClose={() => {
                setDeckSelected('')
                setIsVisibleModalAddDeck(false)
            }} >
                <div style={{fontSize: 20}}>
                    Add deck to <b>{categoryIdSelected?.title || 'untitled'}</b>
                </div>
                <div style={{marginTop: 20}}>
                    <div style={{paddingBottom: 20}}>
                        <div className={styles.label}>
                            Select deck
                        </div>
                        <DropdownSearch
                            className={styles.field}
                            value={deckSelected?.value}
                            placeholder="Search Decks"
                            setValue={handleSetValueDeck}
                            options={decks.value.map((deck) => {
                                return{
                                    label: deck?.title,
                                    value: deck?._id
                                }
                            } )}
                            isLoading={decks.isLoading}
                            timeoutSearch={500}
                            onSearch={handleSearchDeck}
                        />
                        <div style={{ marginTop: 20 }}>
                            <div style={{fontSize: 14}}>
                                Deck Selected
                            </div>
                            <div style={{fontSize: 16, marginTop: 10}}>
                                {deckSelected?.label || 'none'}
                            </div>
                        </div>
                        <button disabled={!deckSelected} onClick={()=>handleAddDeck()} style={{width: '100%', marginTop: 30}} className={classNames("button-small", styles.button)}>
                            Add deck
                        </button>
                    </div>
                </div>
            </Modal>
            <div>
                <div className={styles.inline}>
                    <div style={{ display: 'flex', alignItems: 'center'}} className={classNames(styles.nav, "tablet-hide")}>
                        {navigation.map((x, index) => (
                            <button
                                className={classNames(styles.link, {
                                [styles.active]: x === activeIndex,
                                })}
                                onClick={() => setActiveIndex(x)}
                                key={index}
                            >
                                {x}
                            </button>
                        ))}
                    </div>
                    <div>
                        <Dropdown
                            className={styles.dropdown}
                            classDropdownHead={styles.dropdownHead}
                            value={sorting?.value}
                            setValue={handleAddSort}
                            options={intervals}
                            small
                        />
                    </div>
                </div>
                {activeIndex === 'Available' &&
                    <div className={styles.listCategories}>
                        {categories?.map((category, index)=>(
                            <div key={index} className={styles.deckWrapper}>
                                <div className={styles.inlineFlex}>
                                    <div>
                                        <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
                                            {category?.title}
                                        </div>
                                    </div>
                                    <div className={styles.inlineFlex}>
                                        <button onClick={()=>handleShowEditCategory(category)} className={classNames("button-stroke button-small", styles.button)}>
                                            Edit Translations
                                        </button>
                                        {category?.subCategories?.length === 0 &&
                                            <button onClick={()=>handleShowAddDeck(category)} style={{marginLeft: 10}} className={classNames("button-small", styles.button)}>
                                                <BiPlus />
                                                Add deck
                                            </button>
                                        }
                                    </div>
                                </div>
                                {category?.subCategories?.map((sub, index)=>{
                                    return(
                                        <div key={index} style={{marginTop: 20}}>
                                            <div className={styles.inlineFlex}>
                                                <div>
                                                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {sub?.title}
                                                    </div>
                                                    <div style={{fontSize: '12px', color: 'gray', paddingTop: 10}} className={styles.inlineFlex}>
                                                        <div style={{paddingRight: 10, borderRight: '1px solid lightgray'}} >
                                                            <b>{sub?.decks?.length}</b>
                                                            <span>
                                                                {' decks'}
                                                            </span>
                                                        </div>
                                                        <div style={{paddingLeft: 10}}>
                                                            <b>{sub?.numCards || '0'}</b>
                                                            <span>
                                                                {' words'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.inlineFlex}>
                                                    <button onClick={()=>handleShowEditCategory(sub)} style={{marginRight: 10}} className={classNames("button-stroke button-small", styles.button)}>
                                                        Edit Translations
                                                    </button>
                                                    <button onClick={()=>handleShowAddDeck(sub)} className={classNames("button-small", styles.button)}>
                                                        <BiPlus />
                                                        Add deck
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={styles.inlineScroll} >
                                                {sub?.decks?.map((deck, i)=>{

                                                    const actions = [
                                                        {
                                                        title: "Delete",
                                                        icon: "trash",
                                                        action: () => {
                                                            console.log(deck)
                                                            setCategoryIdSelected(category)
                                                            setIsVisibleModalDeleteDeck(true)
                                                            setDeckSelected(deck)
                                                        },
                                                        },
                                                    ];

                                                    return(
                                                        <div  key={i} className={styles.deckWrapperWW} >
                                                            <div style={{ cursor: 'pointer'}} onClick={()=>history.push(`/deck/edit/${deck?._id}`)} className={styles.imgWrapperWW}>
                                                                <img 
                                                                    srcSet={`${!deck?.avatar?.miniUrl?.includes('undefined') ? deck?.avatar?.miniUrl : "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"} 2x`}
                                                                    src={!deck?.avatar?.miniUrl?.includes('undefined') ? deck?.avatar?.miniUrl : "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"}
                                                                    className={styles.imgObjectFit} 
                                                                    alt="Logo"
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null; // prevents looping
                                                                        currentTarget.src="https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className={styles.deckInfoWW}>
                                                                <div style={{ fontSize: '14px'}} className={styles.truncate}>
                                                                    {deck?.title || 'Untitled'}
                                                                </div>
                                                                <div style={{fontSize: '12px', color: 'gray'}}>
                                                                    {deck?.cards?.length || '0'} words
                                                                </div>
                                                            </div>
                                                            {deck?.premium?.active &&
                                                                <div className={styles.deckPremiumWW}>
                                                                    <div className={classNames("status-green", styles.status)}>
                                                                        Premium
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className={styles.deckConfigWW}>
                                                                <Actions
                                                                    className={styles.actions}
                                                                    classActionsHead={styles.actionsHead}
                                                                    items={actions}
                                                                    small
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className={styles.inlineScroll} >
                                    {category?.decks?.map((deck, i)=>{

                                        const actions = [
                                            {
                                            title: "Delete",
                                            icon: "trash",
                                            action: () => {
                                                console.log(deck)
                                                setCategoryIdSelected(category)
                                                setIsVisibleModalDeleteDeck(true)
                                                setDeckSelected(deck)
                                            },
                                            },
                                        ];

                                        return(
                                            <div key={i} className={styles.deckWrapperWW} >
                                                <div style={{ cursor: 'pointer'}} onClick={()=>history.push(`/deck/edit/${deck?._id}`)} className={styles.imgWrapperWW}>
                                                    <img 
                                                        srcSet={`${!deck?.avatar?.miniUrl?.includes('undefined') ? deck?.avatar?.miniUrl : "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"} 2x`}
                                                        src={!deck?.avatar?.miniUrl?.includes('undefined') ? deck?.avatar?.miniUrl : "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"}
                                                        className={styles.imgObjectFit} 
                                                        alt="Logo"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src="https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                                                        }}
                                                    />
                                                </div>
                                                <div className={styles.deckInfoWW}>
                                                    <div style={{ fontSize: '14px'}} className={styles.truncate}>
                                                        {deck?.title || 'Untitled'}
                                                    </div>
                                                    <div style={{fontSize: '12px', color: 'gray'}}>
                                                        {deck?.cards?.length || '0'} words
                                                    </div>
                                                </div>
                                                {deck?.premium?.active &&
                                                    <div className={styles.deckPremiumWW}>
                                                        <div className={classNames("status-green", styles.status)}>
                                                            Premium
                                                        </div>
                                                    </div>
                                                }
                                                <div className={styles.deckConfigWW}>
                                                    <Actions
                                                        className={styles.actions}
                                                        classActionsHead={styles.actionsHead}
                                                        items={actions}
                                                        small
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </Card>
    );
}

export default Categories;