import React from "react";
import cn from "classnames";
import styles from "./Card.module.sass";

const Card = ({
  className,
  title,
  helper,
  classTitle,
  maxHeight,
  classCardHead,
  head,
  isStyle = true,
  children,
}) => {
  return (
    <div style={{ height: maxHeight ? '76vh' : 'auto' }} className={cn(isStyle ? styles.card : null, className)}>
      {title && (
        <div className={cn(styles.head, classCardHead)}>
          <div className={cn(classTitle, styles.title)}>
            <span>
              {title}
            </span>
            <span className={styles.textHelper}>
              {helper}
            </span>
          </div>
          {head && head}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
