import React from "react";
import styles from "./StoryCard.module.sass";
import { toPng } from "html-to-image";
import cn from "classnames";
import JSZip from "jszip";
import { useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Textfit } from "@ayushmw/react-textfit";
import { useDispatch, useSelector } from "react-redux";
import { parseSearchCardTags } from "../../../../utils";
import { fetchCards } from "../../../../app/cards/cards.slice";
import DropdownSearch from "../../../../components/DropdownSearch";
import { useEffect } from "react";
import Dropdown from "../../../../components/Dropdown";
import { fetchDecks } from "../../../../app/decks/decks.slice";
import Switch from "../../../../components/Switch";
import { BiDownload, BiImage, BiPalette, BiVideo, BiX } from "react-icons/bi";
import { AiFillSound } from "react-icons/ai";

const StoryCard = ({ selectedCardProp, selectedDeckProp, setSelectedCardProp, setSelectedDeckProp }) => {
  const dispatch = useDispatch();
  const { decks, cards } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);

  const [decksOptions, setDecksOptions] = useState(decks);

  const [selectedCard, setSelectedCard] = useState("");
  const [selectedDeck, setSelectedDeck] = useState("");
  const [languageSelected, setLanguageSelected] = useState("en");

  const [isBackground, setIsBackground] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isPlusBadge, setIsPlusBadge] = useState(false);
  const [isLanguageFlags, setIsLanguageFlags] = useState(true);

  const [colorSelected, setColorSelected] = useState("#FFFFFF");
  const [imageSelected, setImageSelected] = useState("");

  const ref_1 = useRef(null);
  const ref_2 = useRef(null);

  const handleSearchCard = (term) => {
    dispatch(
      fetchCards({
        take: 100,
        skip: 0,
        search: term,
      })
    );
  };

  useEffect(() => {
    if (selectedCardProp && selectedDeckProp) {
      setSelectedCard(selectedCardProp);
      setSelectedDeck(selectedDeckProp);
    }
  }, [selectedDeckProp, selectedCardProp]);

  const handleSearchDeck = (term) => {
    dispatch(
      fetchDecks({
        take: 100,
        skip: 0,
        search: term,
      })
    );
  };

  const handleDownloadVideos = () => {
    console.log("descargando videos...");
  };

  const handleAddImage = (e) => {
    console.log("e", e.target.files);
    const [file] = e.target.files;
    if (file) {
      let url = URL.createObjectURL(file);
      setImageSelected(url);
    }
  };

  const handleDownloadPNG = useCallback(
    (type) => {
      setIsLoading(true);
      if (ref_1.current === null) {
        return;
      }

      if (type === 1 || type === "all") {
        toPng(ref_1.current, { cacheBust: false })
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = "storycard_1.png";
            console.log("dataUrl", dataUrl);
            link.href = dataUrl;
            link.click();
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }

      if (type === 2 || type === "all") {
        toPng(ref_2.current, { cacheBust: false })
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = "storycard_2.png";
            console.log("dataUrl", dataUrl);
            link.href = dataUrl;
            link.click();
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    },
    [ref_1, ref_2]
  );

  useEffect(() => {
    console.log("decks", decks);
    console.log("selectedCard", selectedCard);
  }, [selectedCard, decks]);

  useEffect(() => {
    dispatch(fetchDecks());
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      setColorSelected("#2A2C38");
    } else {
      setColorSelected("#FFFFFF");
    }
  }, [isDarkMode]);

  return (
    <div className={styles.containerFlex}>
      <div
        className={styles["col-span-2"]}
        style={{ background: "white", padding: 20, justifyContent: "center", display: "flex" }}
      >
        <div style={{ width: "700px" }}>
          {!selectedCard || !selectedDeck ? (
            <div>
              <h2 style={{ color: "gray" }}>Please select a Card & Deck to proceed...</h2>
            </div>
          ) : (
            <div className={styles.grid2}>
              <div
                ref={ref_1}
                style={{
                  // 1 Panel
                  width: "350px",
                  height: "622px",
                  backgroundColor: colorSelected,
                  backgroundImage: `url('${imageSelected}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  borderRadius: "6px",
                  border: "1px solid #EAEAEA",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    left: 0,
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: isDarkMode ? "#404356" : "#F5F5F5",
                      borderRadius: "999px",
                      paddingInline: "20px",
                      width: "40%",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {isDarkMode ? (
                      <img src="/images/logo-complete-light.svg" style={{ width: "100%" }} />
                    ) : (
                      <img src="/images/logo-complete.svg" style={{ width: "100%" }} />
                    )}
                  </div>
                </div>

                <div style={{ marginTop: 60 }}>
                  <div style={{ paddingInline: 20 }}>
                    <div className={styles["card-board"]}>
                      <div className={styles["flex-board"]}>
                        <span
                          style={{
                            fontSize: 28,
                            color: isDarkMode ? "white" : "black",
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {selectedCard?.card?.meaning?.character}
                        </span>
                        <div>
                          <div>
                            <span
                              style={{
                                color: "#D5D5DC",
                                fontSize: 10,
                              }}
                            >
                              PN
                            </span>
                            <span
                              style={{
                                marginLeft: 8,
                                color: isDarkMode ? "#828AFF" : "#2934D0",
                                fontSize: 14,
                              }}
                            >
                              {selectedCard?.card?.meaning?.pinyin}
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                color: "#D5D5DC",
                                fontSize: 10,
                              }}
                            >
                              TP
                            </span>
                            <span
                              style={{
                                marginLeft: 8,
                                color: "#888992",
                                fontSize: 14,
                              }}
                            >
                              {selectedCard?.card?.grammars?.[0]?.title}
                            </span>
                          </div>
                          {/* <div>
                                                        <span style={{
                                                            color: '#D5D5DC',
                                                            fontSize: 10
                                                        }}>
                                                            CL
                                                        </span>
                                                        <span style={{
                                                            marginLeft: 8,
                                                            color: '#888992',
                                                            fontSize: 14
                                                        }}>
                                                            {selectedCard?.card?.meaning?.pinyinTone}
                                                        </span>
                                                    </div> */}
                        </div>
                        <div
                          style={{
                            backgroundColor: isDarkMode ? "#3E4154" : "#F1F1F5",
                            color: isDarkMode ? "#888992" : "#888992",
                            paddingInline: 5,
                            paddingTop: 3,
                            paddingBottom: 3,
                            cursor: "pointer",
                            borderRadius: 6,
                          }}
                        >
                          <AiFillSound size="20" />
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: isDarkMode ? "#BEBEBE1A" : "#FFFDCC80",
                          borderColor: isDarkMode ? "#BEBEBE1A" : "#EEECB2",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderRadius: "6px",
                          padding: "10px",
                          color: isDarkMode ? "white" : "black",
                          fontSize: 18,
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        {selectedCard?.card?.meaning?.trans}
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            color: isDarkMode ? "#BAB9DD" : "black",
                          }}
                        >
                          <span
                            style={{
                              color: "#888992",
                              fontSize: 12,
                            }}
                          >
                            EXAMPLE
                          </span>
                          <div
                            style={{
                              backgroundColor: isDarkMode ? "#3E4154" : "#F1F1F5",
                              color: isDarkMode ? "#888992" : "#888992",
                              paddingInline: 5,
                              paddingTop: 3,
                              paddingBottom: 3,
                              cursor: "pointer",
                              borderRadius: 6,
                            }}
                          >
                            <AiFillSound size="20" />
                          </div>
                        </div>
                        <div style={{ color: isDarkMode ? "#BAB9DD" : "black" }}>
                          <div
                            style={{
                              borderBottom: "1px dashed #F1F1F5",
                              fontSize: 18,
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {selectedCard?.card?.examples?.[0]?.character}
                          </div>
                          <div
                            style={{
                              borderBottom: "1px dashed #F1F1F5",
                              fontSize: 14,
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {selectedCard?.card?.examples?.[0]?.pinyin}
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                              paddingTop: 10,
                              paddingBottom: 40,
                            }}
                          >
                            {
                              selectedCard?.card?.examples?.[0]?.translations?.filter(
                                (c) => c?.lang === languageSelected
                              )?.[0]?.trans
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ width: "100%", borderTop: isDarkMode ? "1px solid #50546C" : "1px solid #EAEAEA" }}
                  ></div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      marginTop: 20,
                      color: isDarkMode ? "#BAB9DD" : "black",
                    }}
                  >
                    <div style={{ width: "50px" }}>
                      <img
                        src={
                          selectedDeck?.deck?.avatar?.miniUrl ||
                          selectedDeck?.deck?.avatar?.mediumUrl ||
                          "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"
                        }
                        style={{ width: "50px" }}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                        }}
                      />
                    </div>
                    <div style={{ marginLeft: 15 }}>
                      <div style={{ color: "lightgray", fontWeight: "200" }}>
                        {selectedDeck?.deck?.subCategory?.category?.title}
                      </div>
                      <div>
                        {selectedDeck?.deck?.translations?.filter((c) => c?.lang === languageSelected)?.[0]?.trans ||
                          selectedDeck?.deck?.key}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref={ref_2}
                style={{
                  // 2 Panel
                  width: "350px",
                  height: "622px",
                  backgroundColor: colorSelected,
                  backgroundImage: `url('${imageSelected}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  paddingLeft: 30,
                  paddingRight: 30,
                  borderRadius: "6px",
                  border: "1px solid #EAEAEA",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    left: 0,
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: isDarkMode ? "#404356" : "#F5F5F5",
                      borderRadius: "999px",
                      paddingInline: "20px",
                      width: "40%",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {isDarkMode ? (
                      <img src="/images/logo-complete-light.svg" style={{ width: "100%" }} />
                    ) : (
                      <img src="/images/logo-complete.svg" style={{ width: "100%" }} />
                    )}
                  </div>
                </div>
                <div>
                  <div style={{ color: "#A8A8A8", fontWeight: 300, letterSpacing: 2 }}>LEARN FROM ANY LANGUAGE</div>
                  <div
                    style={{
                      color: isDarkMode ? "#FFFFFF" : "#323232",
                      fontWeight: 800,
                      fontSize: "36px",
                      lineHeight: 1,
                      marginTop: 20,
                    }}
                  >
                    Improve your chinese has never been easier.
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 40 }}
                  >
                    <img style={{ width: 130 }} src={`/images/googleplaycolor.svg`} />
                    <img src={`/images/appstore.svg`} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.separator}>
        <DropdownSearch
          className={styles.field}
          isBackgroundWhite
          label="Deck"
          value={selectedDeck}
          placeholder="Search Decks"
          setValue={(e) => {
            setSelectedDeck(e);
            setSelectedDeckProp(e);
          }}
          options={decks.value.map((deck) => {
            return { label: deck?.key, value: deck?.key, deck: deck };
          })}
          isLoading={decks.isLoading}
          timeoutSearch={500}
          onSearch={handleSearchDeck}
        />
        <DropdownSearch
          className={styles.field}
          isBackgroundWhite
          label="Card"
          isDelete
          value={selectedCard}
          placeholder="Search Cards"
          setValue={(e) => {
            setSelectedCard(e);
            setSelectedCardProp(e);
          }}
          options={cards.value.map((card) => parseSearchCardTags(card))}
          isLoading={cards.isLoading}
          timeoutSearch={500}
          onSearch={handleSearchCard}
        />
        <Dropdown
          className={styles.field}
          classDropdownLabel={styles.label}
          setValue={setLanguageSelected}
          value={languageSelected}
          label="Language"
          isWhite
          options={[
            { label: "English", value: "en" },
            { label: "Español", value: "es" },
            { label: "Portuguese", value: "pt" },
            { label: "French", value: "fr" },
            { label: "Russian", value: "ru" },
          ]}
        />
        <div style={{ borderTop: "1px solid lightgray", width: "100%" }}> </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Background</label>
          <Switch value={isBackground} onChange={() => setIsBackground(!isBackground)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Dark mode</label>
          <Switch value={isDarkMode} onChange={() => setIsDarkMode(!isDarkMode)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Plus badge</label>
          <Switch value={isPlusBadge} onChange={() => setIsPlusBadge(!isPlusBadge)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Languages flags</label>
          <Switch value={isLanguageFlags} onChange={() => setIsLanguageFlags(!isLanguageFlags)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>
            <BiPalette />
            <span style={{ marginLeft: 5 }}>Change bg color</span>
          </label>
          <input
            type="color"
            value={colorSelected}
            onChange={(e) => setColorSelected(e.target.value)}
            style={{ marginLeft: 10 }}
          />
        </div>
        {imageSelected ? (
          <>
            <button
              onClick={() => setImageSelected("")}
              style={{ background: "white", width: "100%" }}
              className={cn("button-small button-stroke", styles.button)}
            >
              <BiImage />
              <span>Remove bg image</span>
            </button>
          </>
        ) : (
          <>
            <input
              onChange={handleAddImage}
              style={{ display: "none" }}
              type="file"
              id="file"
              name="file"
              accept="image/png, image/jpeg, image/jpg, image/webp"
            />
            <label
              for="file"
              style={{ background: "white", width: "100%" }}
              className={cn("button-small button-stroke", styles.button)}
            >
              <BiImage />
              <span>Add bg image</span>
            </label>
          </>
        )}
        <div style={{ borderTop: "1px solid lightgray", width: "100%" }}> </div>
        <div className={styles.onMobile}>
          <button
            disabled={!selectedCard || !selectedDeck}
            onClick={() => handleDownloadPNG(1)}
            style={{ width: "100%" }}
            className={cn("button-normal", styles.button)}
          >
            <BiDownload />
            <span>Download 1</span>
          </button>
          <button
            disabled={!selectedCard || !selectedDeck}
            onClick={() => handleDownloadPNG(2)}
            style={{ width: "100%" }}
            className={cn("button-normal", styles.button)}
          >
            <BiDownload />
            <span>Download 2</span>
          </button>
        </div>
        <button
          disabled={!selectedCard || !selectedDeck}
          onClick={() => handleDownloadPNG("all")}
          style={{ width: "100%" }}
          className={cn("button-normal", styles.button)}
        >
          <BiDownload />
          <span>Download PNGs</span>
        </button>
        <button
          disabled={!selectedCard || !selectedDeck}
          onClick={handleDownloadVideos}
          style={{ width: "100%" }}
          className={cn("button-normal", styles.button)}
        >
          <BiVideo />
          <span>Download videos</span>
        </button>
      </div>
    </div>
  );
};

export default StoryCard;
