import React, { useState } from "react";
import cn from "classnames";
import styles from "./CategoryAndAttibutes.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Tooltip from "../../../components/Tooltip";
import Checkbox from "../../../components/Checkbox";
import { WithContext as ReactTags } from "react-tag-input";
import TextInput from "../../../components/TextInput";
import { FiRepeat } from "react-icons/fi";
import Switch from "../../../components/Switch";

const compatibility = [
  {
    id: "en",
    title: "English",
  },
  {
    id: "es",
    title: "Spanish",
  },
  {
    id: "ru",
    title: "Russian",
  },
  {
    id: "fr",
    title: "French",
  },
  {
    id: "pt",
    title: "Portuguese",
  },
];

const optionsCategory = ["Select category", "Category 1", "Category 2"];

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

const CategoryAndAttibutes = ({ className, selectedLanguages, setSelectedLanguages, languageInputs, setLanguageInputs }) => {

  const handleChange = (id) => {
    if (selectedLanguages.includes(id)) {
      setSelectedLanguages(selectedLanguages.filter((x) => x !== id));
    } else {
      setSelectedLanguages((selectedLanguages) => [...selectedLanguages, id]);
    }
  };

  const handleChangeTextInput = (id, text) =>{
    console.log('id', id)
    console.log('text', text)
    setLanguageInputs({
      ...languageInputs,
      [id]: text
    })
  }

  const handleTrad = (lang) =>{
    const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
    const authorizationHeader = { 'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).token}` }; // Crea el encabezado Authorization

    fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            title: languageInputs?.['en'],
            lang
        }),
        headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader // Agrega el encabezado Authorization a los encabezados de solicitud
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Error en la respuesta de la API');
        }
        return response.json();
    })
    .then(data => {
        console.log('Respuesta de la API:', data);
        let translation = data?.translations?.[0]?.text
        if(translation){
          handleChangeTextInput(String(lang)?.toLowerCase(), translation)
        }
    })
    .catch(error => {
        console.error('Error al llamar a la API:', error);
    });
  }


  return (
    <Card className={cn(styles.card, className)} title="Languages" classTitle="title-purple">
      <div className={styles.images}>
        {/* <Dropdown
          className={styles.field}
          label="Category"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          value={category}
          setValue={setCategory}
          options={optionsCategory}
        /> */}
        <div className={styles.label}>
          Select languages availability and deck name{" "}
          <Tooltip
            className={styles.tooltip}
            title="Maximum 100 characters. No HTML or emoji allowed"
            icon="info"
            place="right"
          />
        </div>
        <div style={{marginTop: 10}} className={styles.inputList}>
          {compatibility.map((c)=>(
            <div style={{position: 'relative'}}>
              <TextInput
                name="amount"
                type="text"
                tooltip="Small description"
                required
                currency={<span style={{color: 'gray'}}>{c?.id.toUpperCase()}</span>}
                value={languageInputs[c?.id]}
                onChange={(e)=>handleChangeTextInput(c?.id, e.target.value)}
              />
              <div style={{ position: 'absolute', right: 20, top:0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <button onClick={()=>handleTrad(String(c?.id)?.toUpperCase())} style={{ marginRight: 20 }}>
                      <FiRepeat size="20" />
                  </button>
                  <Switch
                      value={selectedLanguages.includes(c?.id)}
                      onChange={()=> handleChange(c?.id)}
                  />
              </div>
            </div>
          ))}
        </div>
        {/* <div className={styles.head}>
          <div className={styles.label}>
            Tags{" "}
            <Tooltip
              className={styles.tooltip}
              title="Maximum 100 characters. No HTML or emoji allowed"
              icon="info"
              place="right"
            />
          </div>
          <div className={styles.counter}>
            <span>1</span>/12 tags
          </div>
        </div>
        <div className={styles.tags}>
          <ReactTags
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            delimiters={delimiters}
            handleTagClick={handleTagClick}
            onClearAll={onClearAll}
            onTagUpdate={onTagUpdate}
            suggestions={[{ id: "1", text: "Geometry" }]}
            placeholder="Enter tags to describe your item"
            minQueryLength={2}
            maxLength={20}
            autofocus={false}
            allowDeleteFromEmptyInput={true}
            autocomplete={true}
            readOnly={false}
            allowUnique={true}
            allowDragDrop={true}
            inline={true}
            inputFieldPosition="inline"
            allowAdditionFromPaste={true}
            editable={true}
            clearAll={true}
            tags={tags}
          />
        </div> */}
      </div>
    </Card>
  );
};

export default CategoryAndAttibutes;
