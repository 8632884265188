import { deleteApi, getApi, patchApi, postApi, putApi } from "./general.service";

export const createTexts = async (data) => {
  const texts = postApi("/admin/text", data);
  return texts;
};

export const createTextsMedia = async (data) => {
  const texts = postApi("/admin/text/media", data);
  return texts;
};

export const updateTexts = async (data) => {
  const texts = putApi("/admin/text", data);
  return texts;
};

export const getTexts = async (data) => {
  const texts = getApi("/admin/text", data);
  return texts;
};

export const getTextID = async (data, id) => {
  const texts = getApi(`/admin/text/${id}`, data);
  return texts;
};

export const getTextsContent = async (data) => {
  const texts = getApi(`/admin/text/content/${data?._id}`, data);
  return texts;
};

export const putTextsContent = async (data, id) => {
  const texts = putApi(`/admin/text/content/${id}`, data);
  return texts;
};

export const deleteTexts = async (data) => {
  const texts = deleteApi(`/admin/text?_id=${data?._id}`, data);
  return texts;
};

export const createQuestionText = async (data) => {
  const texts = patchApi("/admin/questionnaire", data);
  return texts;
};

export const getQuestionsText = async (textId, data) => {
  const texts = getApi(`/admin/questionnaire/${textId}`, data);
  return texts;
};

export const deleteQuestion = async (data) => {
  const texts = deleteApi(`/admin/questionnaire?_id=${data?._id}`, data);
  return texts;
};

export const uploadVideoSentence = async (data) => {
  const resp = postApi("/admin/text/sentence/media", data);
  return resp;
};
