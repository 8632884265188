import React from "react";
import styles from "./TopWords.module.sass";
import TableComponent from "../../../../../components/TableComponent";
import { BiPlus } from "react-icons/bi";
import useState from "react-usestateref";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Modal from "../../../../../components/Modal";
import { fetchCards } from "../../../../../app/cards/cards.slice";

const columns = [
  {
    header: "Card",
    accessor: "cardName",
  },
  {
    header: "Pinyin",
    accessor: "category",
  },
  {
    header: "Char",
    accessor: "level",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

const TopWords = ({ cardsSelected, setCardsSelected, isEdit }) => {
  const dispatch = useDispatch();

  const { cards } = useSelector((state) => state);

  const [visibleModal, setVisibleModal] = useState(false);
  const [cardsTable, setCardsTable] = useState([]);

  const [cardTable, setCardTable] = useState([]);

  useEffect(() => {
    dispatch(
      fetchCards({
        take: 100,
        skip: 0,
      })
    );
  }, []);

  const handleSearchCard = (e) => {
    dispatch(
      fetchCards({
        take: 100,
        skip: 0,
        search: e,
      })
    );
  };

  useEffect(() => {
    if (isEdit && cardsSelected && cardTable.length === 0) {
      let _card = [];

      cardsSelected?.forEach((c) => {
        _card.push(handleParseDataCard(c));
      });

      if (_card.length > 0) {
        setCardTable(_card);
      }
    }
  }, [isEdit, cardsSelected, cardTable]);

  const handleParseDataCard = (card) => {
    return {
      cardName: {
        data: card?.meaning?.trans,
        dataComponent: card?.meaning?.trans,
      },
      category: {
        data: card?.meaning?.pinyin,
        dataComponent: card?.meaning?.pinyin,
      },
      level: {
        data: card?.meaning?.character,
        dataComponent: card?.meaning?.character,
      },
      _id: card?._id,
      actions: {
        data: "actions",
        dataComponent: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <button style={{ color: "blue" }} onClick={() => window.open(`/card/edit/${card?._id}`, "_blank")}>
              View
            </button>
            <button style={{ color: "red", marginLeft: 20 }} onClick={() => handleDeleteCardTableID(card?._id)}>
              Delete
            </button>
          </div>
        ),
      },
    };
  };

  const handleDeleteCardTableID = (id) => {
    setCardTable((cardTable) => cardTable?.filter((c) => c._id !== id));
    setCardsSelected(cardTable?.filter((c) => c._id !== id)?.map((c) => c?._id));
  };

  const handleAddCardTableID = (card) => {
    if (!cardTable?.find((cardId) => cardId === card?._id)) {
      let _cardTable = [...cardTable, handleParseDataCard(card)];
      setCardTable(_cardTable);
      setCardsSelected(_cardTable?.map((c) => c?._id));
      setVisibleModal(false);
    }
  };

  useEffect(() => {
    if (cards?.value) {
      setCardsTable(
        cards?.value?.map((card) => {
          return {
            cardName: {
              data: card?.meaning?.trans,
              dataComponent: card?.meaning?.trans,
            },
            category: {
              data: card?.meaning?.pinyin,
              dataComponent: card?.meaning?.pinyin,
            },
            level: {
              data: card?.meaning?.character,
              dataComponent: card?.meaning?.character,
            },
            _id: card?._id,
            actions: {
              data: "actions",
              dataComponent: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button style={{ color: "blue" }} onClick={() => handleAddCardTableID(card)}>
                    Add
                  </button>
                </div>
              ),
            },
          };
        })
      );
    }
  }, [cards, cardTable]);

  const handleShowModalAddDeck = () => {
    setVisibleModal(true);
  };

  return (
    <div>
      <Modal outerClassName={styles.outer} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <div className={styles.success}>
          <TableComponent title="Keywords" data={cardsTable} columns={columns} handleSearchAPI={handleSearchCard} />
        </div>
      </Modal>
      <TableComponent
        data={cardTable}
        columns={columns}
        isButton={true}
        buttonText="Add Keyword"
        buttonIcon={<BiPlus size="24" />}
        buttonHandler={handleShowModalAddDeck}
        title="Keywords"
      />
    </div>
  );
};

export default TopWords;
