import { getApi, putApi} from "./general.service";

export const getCategory = async (data) => {
  const category = getApi("/admin/category", data);
  return category;
};

export const updateCategory = async (data) => {
  const category = putApi("/admin/category", data);
  return category;
};