import { deleteApi, getApi, postApi, putApi } from "./general.service";

export const getClasses = async (data) => {
  const classes = getApi("/admin/classes", data);
  return classes;
};

export const getClasse = async (data) => {
  const classes = getApi(`/admin/classes/${data._id}`, data);
  return classes;
};

export const createClasse = async (data) => {
  const classes = postApi("/admin/classes", data);
  return classes;
};

export const updateClasse = async (data) => {
  const classes = putApi("/admin/classes", data);
  return classes;
};

export const deleteClasse = async (data) => {
  const classes = deleteApi(`/admin/classes?_id=${data._id}`, data);
  return classes;
};
