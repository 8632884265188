import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getClassifications } from "../../api/classification.service";

export const fetchClassification = createAsyncThunk("classification/fetchClassification", async (data, { rejectWithValue }) => {
  const classification = await getClassifications();
  console.log("****+classification -> ", classification);
  return classification.data;
});

const fetchClassificationReducer = {
  [fetchClassification.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },
  [fetchClassification.fulfilled]: (state, action) => {
    const classification = action.payload.results;
    state.value = classification;
    state.error = null;
    state.isLoading = false;
  },
  [fetchClassification.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};


export const classificationSlice = createSlice({
    name: "classification",
    initialState: {
      value: [],
      error: null,
      editClassificationValue: null,
      isLoading: false,
      isLoadingCreate: false,
    },
    reducers: {
      cleanClassification: (state, action) => {
        state.value = [];
      },
    },
    extraReducers: {
      ...fetchClassificationReducer,
    },
  });
  
  export const { cleanClassification } = classificationSlice.actions;
  
  export default classificationSlice.reducer;