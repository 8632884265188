import { deleteApi, getApi, postApi, putApi } from "./general.service";

export const getDecks = async (data) => {
  const decks = getApi(`/admin/deck${
    data?.search ? `?search=${data.search}` : ""
  }`, data);
  return decks;
};

export const getDeck = async (data) => {
  const decks = getApi(`/admin/deck/${data._id}`, data);
  return decks;
};

export const getDeckStats = async (data) => {
  const decks = getApi(`/stats/deck/${data._id}?take=${data?.take || 20}${
    data?.search ? `&search=${data.search}` : ""
  }${data?.nextPageHash ? `&nextPageHash=${data.nextPageHash}` : ''}`, data);
  return decks;
};

export const createDeck = async (data) => {
  const decks = postApi("/admin/deck", data);
  return decks;
};

export const getDeckCSV = async (data) => {
  const decks = getApi(`/admin/deck/${data._id}/export/csv`, data);
  return decks;
};

export const updateDeck = async (data) => {
  const decks = putApi("/admin/deck", data);
  return decks;
};

export const deleteDeck = async (data) => {
  const decks = deleteApi(`/admin/deck?_id=${data._id}`, data);
  return decks;
};

export const updateDeckAvatar = async (data) => {
  const decks = postApi("/admin/deck/avatar", data, { headers: { 'Content-Type': 'multipart/form-data'}});
  return decks;
};