import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBugs } from "../../api/bug.service";

export const fetchBug = createAsyncThunk("bug/fetchBug", async (data, { rejectWithValue }) => {
  const bug = await getBugs();
  console.log("****+bug -> ", bug);
  return bug.data;
});

const fetchBugReducer = {
  [fetchBug.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },
  [fetchBug.fulfilled]: (state, action) => {
    const bug = action.payload.results;
    state.value = bug;
    state.error = null;
    state.isLoading = false;
  },
  [fetchBug.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};


export const bugSlice = createSlice({
    name: "bug",
    initialState: {
      value: [],
      error: null,
      editBugValue: null,
      isLoading: false,
      isLoadingCreate: false,
    },
    reducers: {
      cleanBug: (state, action) => {
        state.value = [];
      },
    },
    extraReducers: {
      ...fetchBugReducer,
    },
  });
  
  export const { cleanBug } = bugSlice.actions;
  
  export default bugSlice.reducer;