import React, { useState } from "react";
import cn from "classnames";
import styles from "./Popover.module.sass";
import Icon from "../Icon";

const Popover = ({ className, actionable, children, title }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className={cn(styles.filters, className, { [styles.active]: visible })}
    >
      <button
        className={cn("", styles.head)}
        onClick={() => setVisible(true)}
      >
        {actionable}
      </button>
      <div className={styles.body}>
        <div className={styles.top}>
          <div className={cn("title-red", styles.title)}>{title}</div>
          <button className={styles.close} onClick={() => setVisible(false)}>
            <Icon name="close" size="20" />
          </button>
        </div>
        {children}
      </div>
      <div className={styles.overlay} onClick={() => setVisible(false)}></div>
    </div>
  );
};

export default Popover;
