import React, { useState } from "react";
import cn from "classnames";
import styles from "./Price.module.sass";
import Card from "../../../../../components/Card";
import Tooltip from "../../../../../components/Tooltip";
import TextInput from "../../../../../components/TextInput";
import Switch from "../../../../../components/Switch";

const Price = ({ 
  className,
  priceAvailability,
  setPriceAvailability,
  textPrice,
  setTextPrice
}) => {
  const [resolution, setResolution] = useState(true);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Price"
      classTitle="title-green"
    >
      <div className={styles.price}>
        <div className={styles.line}>
          <div className={styles.info}>
            Premium text{" "}
            <Tooltip
              className={styles.tooltip}
              title="Maximum 100 characters. No HTML or emoji allowed"
              icon="info"
              place="top"
            />
          </div>
          <Switch
            className={styles.switch}
            value={priceAvailability}
            onChange={() => setPriceAvailability(!priceAvailability)}
          />
        </div>
        <TextInput
          className={styles.field}
          label="Text price"
          name="amount"
          type="number"
          tooltip="Small description"
          required
          currency="$"
          onChange={(ev) => setTextPrice(Number(ev.target.value))}
          value={textPrice}
        />
      </div>
    </Card>
  );
};

export default Price;
