export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

export const parseSearchCardTags = (card) => {
  return {
    character: `${card?.meaning?.character}`,
    label: `${card?.meaning?.pinyin} - ${card?.meaning?.trans}`,
    translation: `${card?.meaning?.trans}`,
    pinyin: card?.meaning?.pinyin,
    value: card?._id,
    card: card
  };
};

export const parseSearchClassesTags = (classe) => {
  return {
    label: classe.title,
    value: classe._id,
  };
};

export const copyOf = (p) => {
  return JSON.parse(JSON.stringify(p));
};
