import { deleteApi, getApi, postApi, putApi } from "./general.service";

export const getGrammars = async (data) => {
  const grammar = getApi("/admin/grammar", data);
  return grammar;
};

export const getGrammar = async (data) => {
  const grammar = getApi(`/admin/grammar/${data._id}`, data);
  return grammar;
};

export const createGrammar = async (data) => {
  const grammar = postApi("/admin/grammar", data);
  return grammar;
};

export const updateGrammar = async (data) => {
  const grammar = putApi("/admin/grammar", data);
  return grammar;
};

export const deleteGrammar = async (data) => {
  const grammar = deleteApi(`/admin/grammar?_id=${data._id}`, data);
  return grammar;
};
