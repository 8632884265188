import { deleteApi, getApi, postApi, putApi } from "./general.service";

export const getUsers = async (data) => {
  const users = getApi(`/admin/user?take=${data?.take || 20}&page=${data?.skip || 0}${
    data?.search ? `&search=${data.search}` : ""
  }${data?.nextPageHash ? `&nextPageHash=${data.nextPageHash}` : ''}`, data);
  return users;
};

export const getUser = async (data) => {
  const users = getApi(`/admin/user/${data._id}`, data);
  return users;
};

export const createUser = async (data) => {
    const users = postApi("/admin/user", data);
    return users;
};

export const updateUser = async (data) => {
    const users = putApi("/admin/user", data);
    return users;
};

export const deleteUser = async (data) => {
    const users = deleteApi(`/admin/user?_id=${data._id}`, data);
    return users;
};