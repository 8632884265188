import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = REACT_APP_API_URL;

export const getApi = async (url, data) => {
  try {
    const resp = await axios.get(url, data);
    return resp;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const postApi = async (url, data, headers) => {
  try {
    const resp = await axios.post(url, data, headers);
    return resp;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const patchApi = async (url, data, headers) => {
  try {
    const resp = await axios.patch(url, data, headers);
    return resp;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const putApi = async (url, data) => {
  try {
    const resp = await axios.put(url, data);
    return resp;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const deleteApi = async (url, data) => {
  try {
    const resp = await axios.delete(url, data);
    return resp;
  } catch (err) {
    handleError(err);
    return err;
  }
};

const handleError = async (err) => {
  console.log("api error --> ", err);
  if (err.response.status === 401) {
    localStorage.clear();
    window.location.reload();
  }
};
