import React from 'react'
import { useState } from 'react';
import styles from './CardInfo.module.sass'
import Card from '../../../components/Card';
import Switch from '../../../components/Switch';
import TextInput from '../../../components/TextInput';
import SelectedTags from '../../../components/SelectedTags';
import Dropdown from '../../../components/Dropdown';


const KeyCodes = {
    comma: 188,
    enter: 13,
};

const CardInfo = ({
    cardAvailavility,
    setCardAvailavility,
    cardId,
    setCardId,
    grammars = [],
    setGrammars,
    classification = [],
    setClassification,
    grammarOptions,
    classificationOptions
}) => {


    const handleAddGrammar = (e) => {
        setGrammars((currentVal) => [...currentVal, grammarOptions?.filter(c=> c?.value === e)?.[0]]);
    };

    const handleRemoveGrammar = (tag) => {
        setGrammars((currentVal) => currentVal.filter((c) => c?.value !== tag.value));
    };

    const handleAddClassification = (e) => {
        setClassification((currentVal) => [...currentVal, classificationOptions?.filter(c=> c?.value === e)?.[0]]);
    };

    const handleRemoveClassification = (tag) => {
        setClassification((currentVal) => currentVal.filter((c) => c?.value !== tag.value));
    };

    return (
        <Card
            title="Card info"
            classTitle="title-green"
        >
            <div>
                <div className={styles.line}>
                    <div className={styles.info}>
                        Card availability
                    </div>
                    <Switch
                        className={styles.switch}
                        value={cardAvailavility}
                        onChange={() => setCardAvailavility(!cardAvailavility)}
                    />
                </div>
                <TextInput
                    name="CardID"
                    type="text"
                    label="Card ID (blocked)"
                    tooltip="Small description"
                    required
                    disabled
                    value={cardId}
                    onChange={(e)=>setCardId(e.target.value)}
                />
                <div style={{ marginTop: 25 }}>
                    <div style={{ marginTop: 12 }}>
                        <Dropdown
                            className={styles.field}
                            classDropdownLabel={styles.label}
                            setValue={handleAddGrammar}
                            options={grammarOptions}
                            label="Grammar"
                        />
                    </div>
                    <div style={{ marginTop: 12 }}>
                    {grammars?.length === 0 ? (
                        "Select grammar"
                    ) : (
                        <SelectedTags
                        tags={grammars?.map((el, index) => ({
                            value: el?.value,
                            id: `${index}`,
                            title: el?.label,
                        }))}
                        onTagRemove={handleRemoveGrammar}
                        />
                    )}
                    </div>
                </div>

                <div style={{ marginTop: 25 }}>
                    <div style={{ marginTop: 12 }}>
                        <Dropdown
                            className={styles.field}
                            classDropdownLabel={styles.label}
                            setValue={handleAddClassification}
                            options={classificationOptions}
                            label="Classification"
                        />
                    </div>
                    <div style={{ marginTop: 12 }}>
                    {classification?.length === 0 ? (
                        "Select classification"
                    ) : (
                        <SelectedTags
                            tags={classification?.map((el, index) => ({
                                value: el?.value,
                                id: `${index}`,
                                title: el?.label,
                            }))}
                            onTagRemove={handleRemoveClassification}
                        />
                    )}
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default CardInfo;