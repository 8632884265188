import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  isWhite,
  label,
  tooltip,
  small,
  upBody,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
  };

  const labelValue = options ? options.find((op) => op?.value === value) : ''

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label} {tooltip && <Tooltip className={styles.tooltip} title={tooltip} icon="info" place="right" />}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div style={isWhite ? {background: 'white'} : {}} className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
          <div className={styles.selection}>{labelValue ? labelValue.label : value}</div>
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {options?.map((x, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x.value === value,
              })}
              onClick={() => handleClick(x.value, index)}
              key={index}
            >
              {x.label}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
