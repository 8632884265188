import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router";
import classNames from 'classnames';
import { toast } from 'sonner';
import styles from "./AddBug.module.sass";
import DropdownSearch from '../../components/DropdownSearch';
import { fetchCards } from '../../app/cards/cards.slice';
import { parseSearchCardTags } from '../../utils';
import { AVAILABLE_LANGS } from '../../utils/constants';
import TextInput from '../../components/TextInput';
import Dropdown from '../../components/Dropdown';
import Loader from '../../components/Loader';
import { createBug, createBugPublic } from '../../api/bug.service';
import { fetchBug } from '../../app/bugs/bugs.slice';

const AddBug = ({ selectedCardProp, setVisible, isPadding }) => {

    const location = useLocation()
    const history = useHistory();
    const dispatch = useDispatch();
    const { bugs, cards } = useSelector((state) => state);

    const [selectedCard, setSelectedCard] = useState('')
    const [bugType, setBugType] = useState('')
    const [reason, setReason] = useState('')
    const [system, setSystem] = useState('')
    const [lang, setLang] = useState('')

    const params = new URLSearchParams(location.search);

    const [tokenParam, setTokenParam] = useState('')
    const [langParam, setLangParam] = useState('')
    const [deviceParam, setDeviceParam] = useState('')
    const [cardParam, setCardParam] = useState('')
    const [userParam, setUserParam] = useState('')

    const [isSuccess, setIsSuccess] = useState(false)

    console.log("total cards -> ", cards)

    useEffect(() => {
        let token = params.get('token')
        let lang = params.get('lang')
        let device = params.get('device')
        let card = params.get('card')
        let user = params.get('user')

        setTokenParam(token)
        setLangParam(lang)
        setDeviceParam(device)
        setCardParam(card)
        setUserParam(user)

        if(card){
            setSelectedCard({label: card ,value: card})
        }

        if(lang){
            console.log("lang", lang)
            setLang(lang)
        }
    }, [])
    

    const handleSearch = (term) => {
        dispatch(
          fetchCards({
            take: 100,
            skip: 0,
            search: term,
          })
        );
    };

    const handleSetValue = (e) =>{
        setSelectedCard(e)
    }

    useEffect(() => {
        if(selectedCardProp){
            setSelectedCard({label: `${selectedCardProp?.meaning?.character} - ${selectedCardProp?.meaning?.trans}` ,value: selectedCardProp?._id})
        }
    }, [selectedCardProp])
    

    const handleSave = async () =>{
        let json = {
            active: true,
            card: cardParam || selectedCard?.value,
            bugType,
            reason,
            system: deviceParam || String(navigator.userAgent),
            lang: langParam || lang,
            user: userParam
        }

        // if(tokenParam){
        //     json.token = tokenParam
        // }

        const resp = await createBugPublic(json)

        if(resp?.data?.card){
            // dispatch(fetchBug());
            // if(setVisible){
            //     setVisible(false)
            // }
            setIsSuccess(true)
            toast.success('Bug created successfully')
        }
    }

    useEffect(() => {
        document.body.classList.remove('dark-mode')
        document.body.classList.add('light-mode')
    }, [])
    

    return (
        <div style={{marginTop: 0, padding: isPadding ? 40 : 0, background: '#FCFCFC', width: '100%', height: '100vh'}}>
            <h2>
                {'Report Bug'}
            </h2>
            <div className={styles.spaceYinputs} style={{marginTop: 20, background: isPadding ? 'white' : 'transparent', borderRadius: isPadding ? 8 : 0, padding: isPadding ? 20 : 0}}>
                <div>
                    {!selectedCardProp &&
                        <>
                        {!cardParam &&
                        <>
                            <div className={styles.label}>
                                Select card
                            </div>
                            <DropdownSearch
                                className={styles.field}
                                tooltip="Maximum 100 characters. No HTML or emoji allowed"
                                value={selectedCard}
                                placeholder="Search Cards"
                                setValue={handleSetValue}
                                options={cards.value.map((card) => parseSearchCardTags(card))}
                                isLoading={cards.isLoading}
                                timeoutSearch={500}
                                onSearch={handleSearch}
                            />
                        </>
                        }
                        </>
                    }
                    <div style={{ marginTop: 20 }}>
                        <div style={{fontSize: 14, color: '#000111'}}>
                            Card Selected
                        </div>
                        <div style={{fontSize: 16, marginTop: 10, color: '#000111'}}>
                            {selectedCard?.label || 'none'}
                        </div>
                    </div>
                </div>
                <div style={{fontSize: 14, color: '#000111'}}>
                    Bug type
                </div>
                <Dropdown
                    className={styles.field}
                    classDropdownLabel={styles.label}
                    setValue={setBugType}
                    value={bugType}
                    white
                    options={[
                        {label: 'Translation', value: 'Translation'},
                        {label: 'AppError', value: 'AppError'},
                        {label: 'VideoMismatch', value: 'VideoMismatch'},
                        {label: 'WrongContextExample', value: 'WrongContextExample'},
                        {label: 'WordTranslationError', value: 'WordTranslationError'},
                        {label: 'Context1TransError', value: 'Context1TransError'},
                        {label: 'Context2TransError', value: 'Context2TransError'},
                        {label: 'PinyinError', value: 'PinyinError'},
                        {label: 'MissVideo', value: 'MissVideo'},
                    ]}
                />
                <div style={{fontSize: 14, color: '#000111'}}>
                    Reason
                </div>
                <TextInput
                    name="Reason"
                    type="text"
                    white
                    required
                    value={reason}
                    onChange={(e)=>setReason(e.target.value)}
                />
                <div style={{fontSize: 14, color: '#000111'}}>
                    Language
                </div>
                <Dropdown
                    className={styles.field}
                    classDropdownLabel={styles.label}
                    setValue={setLang}
                    value={lang}
                    white
                    options={AVAILABLE_LANGS?.map((lang)=>{
                        return{
                            label: lang, value: lang
                        }
                    })}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <button disabled={!selectedCard?.value || !bugType || !reason || !lang || isSuccess} style={{marginLeft: 20}} className={classNames("button", styles.button)} onClick={handleSave} >
                        {bugs.isLoadingCreate ? <Loader className={styles.loader} /> : isSuccess ? <span>Saved!</span> : <span>Save changes</span>}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddBug;