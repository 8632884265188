import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { createCard, getCard, getCards, updateCard } from "../../api/cards.service";
import { copyOf } from "../../utils";

export const fetchCards = createAsyncThunk("cards/fetchCards", async (data, { rejectWithValue }) => {
  const cards = await getCards(data);
  console.log("***cards --_> ", cards);
  return cards.data;
});

const fetchCardsReducer = {
  [fetchCards.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },
  [fetchCards.fulfilled]: (state, action) => {
    const cards = action.payload;
    state.value = cards.results;
    state.pagination = cards.pagination;
    state.error = null;
    state.isLoading = false;
  },
  [fetchCards.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchEditCard = createAsyncThunk("cards/fetchEditCard", async (data, { rejectWithValue }) => {
  const cards = await getCard(data);
  console.log("****+getcard -> ", cards);
  return cards.data;
});

const fetchEditCardReducer = {
  [fetchEditCard.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
    state.editCardValue = null;
  },
  [fetchEditCard.fulfilled]: (state, action) => {
    const card = action.payload;
    state.editCardValue = card;
    state.error = null;
    state.isLoading = false;
  },
  [fetchEditCard.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchCreateCard = createAsyncThunk("cards/fetchCreateCard", async (data, { rejectWithValue }) => {
  const cards = await createCard(data);
  console.log("****+card created -> ", cards);
  return cards.data;
});

const fetchCreateCardReducer = {
  [fetchCreateCard.pending]: (state, action) => {
    state.isLoadingCreate = true;
    state.isLoading = true;
    state.error = null;
  },
  [fetchCreateCard.fulfilled]: (state, action) => {
    const respData = action.payload;
    state.value.push(respData);
    state.error = null;
    state.editCardValue = null;
    state.isLoadingCreate = false;
    state.isLoading = false;
  },
  [fetchCreateCard.rejected]: (state, action) => {
    state.isLoadingCreate = false;
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchUpdateCard = createAsyncThunk("cards/fetchUpdateCard", async (data, { rejectWithValue }) => {
  const card = await updateCard(data);
  console.log("****+cards -> ", card);
  return card.data;
});

const fetchUpdateCardReducer = {
  [fetchUpdateCard.pending]: (state, action) => {
    state.isLoading = true;
    state.isLoadingCreate = true;
    state.error = null;
  },
  [fetchUpdateCard.fulfilled]: (state, action) => {
    const respData = action.payload;
    const currentValue = copyOf(current(state.value)).filter((v) => v._id !== respData._id);
    state.value = currentValue;
    state.value.unshift(respData);
    state.error = null;
    state.isLoading = false;
    state.isLoadingCreate = false;
  },
  [fetchUpdateCard.rejected]: (state, action) => {
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.error = action.payload;
  },
};

export const cardsSlice = createSlice({
  name: "cards",
  initialState: {
    value: [],
    error: null,
    pagination: null,
    isLoading: false,
  },
  reducers: {
    cleanCards: (state, action) => {
      state.value = [];
    },
  },
  extraReducers: {
    ...fetchCardsReducer,
    ...fetchEditCardReducer,
    ...fetchUpdateCardReducer,
    ...fetchCreateCardReducer
  },
});

export const { cleanCards } = cardsSlice.actions;

export default cardsSlice.reducer;
