import React from 'react'
import styles from "./CardPreview.module.sass";
import Card from '../Card';
import { AiFillSound } from 'react-icons/ai';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Pagination } from "swiper";
import { Navigation } from "swiper";
import { useState } from 'react';
import { useEffect } from 'react';

const CardPreview = ({ card, grammar, classificator }) => {

    const [audioUrl, setAudioUrl] = useState('')

    const handleTextToSpeech = (text) =>{
        var msg = new SpeechSynthesisUtterance();
        msg.text = text;
        msg.lang = 'zh';
        window.speechSynthesis.speak(msg);
    }

    const handleListenAudio = (urlProp) =>{

        let url = audioUrl

        if(urlProp){
            url = urlProp
        }
        const request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'blob';
        
        request.onload = function() {
          if (request.status === 200) {
            const blob = request.response;
            const audio = new Audio(URL.createObjectURL(blob));
            audio.play();
          }
        };
        
        request.send();
    }


    useEffect(() => {
        setAudioUrl(card?.examples?.[0]?.audioOld || card?.examples?.[0]?.audioUrl)
    }, [card])
    

    return (
        <Card
            title="Card preview"
            classTitle="title-blue"
        >
            {card &&
                <div className={styles["card-board"]}>
                    <div className={styles["flex-board"]}>
                        <span style={{ fontSize: 28 }}>
                            {card?.meaning?.character}
                        </span>
                        <div>
                            <div>
                                <span style={{
                                    color: '#D5D5DC',
                                    fontSize: 10
                                }}>
                                    TP
                                </span>
                                <span style={{
                                    marginLeft: 8,
                                    color: '#2934D0',
                                    fontSize: 14
                                }}>
                                    {card?.meaning?.pinyin}
                                </span>
                            </div>
                            <div>
                                <span style={{
                                    color: '#D5D5DC',
                                    fontSize: 10
                                }}>
                                    TP
                                </span>
                                <span style={{
                                    marginLeft: 8,
                                    color: '#888992',
                                    fontSize: 14
                                }}>
                                    {grammar || card?.grammars?.[0]?.title}
                                </span>
                            </div>
                            <div>
                                <span style={{
                                    color: '#D5D5DC',
                                    fontSize: 10
                                }}>
                                    CL
                                </span>
                                <span style={{
                                    marginLeft: 8,
                                    color: '#888992',
                                    fontSize: 14
                                }}>
                                    {classificator || card?.classifications?.[0]?.trans}
                                </span>
                            </div>
                        </div>
                        <div 
                            style={{
                                backgroundColor: "#F1F1F5",
                                color: '#888992',
                                paddingInline: 5,
                                paddingTop: 3,
                                paddingBottom: 3,
                                cursor: "pointer",
                                borderRadius: 6
                            }}
                            onClick={()=>handleListenAudio(card?.meaning?.audioOld || card?.meaning?.audioUrl)}
                        >
                            <AiFillSound size="20" />
                        </div>
                    </div>
                    <div style={{
                        backgroundColor: "#FFFDCC80",
                        borderColor: "#EEECB2",
                        borderWidth: '1px',
                        borderStyle: "solid",
                        borderRadius: '6px',
                        padding: "10px",
                        fontSize: 20,
                        fontWeight: 700,
                        textAlign: 'center'
                    }}>
                        {card?.meaning?.trans || card?.meaning?.translations?.find(c => c?.lang === 'en')?.trans}
                    </div>
                    <div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: 'center'}}>
                            <span style={{
                                color: '#888992',
                                fontSize: 12
                            }}>
                                EXAMPLES
                            </span>
                            <div 
                                style={{
                                    backgroundColor: "#F1F1F5",
                                    color: '#888992',
                                    paddingInline: 5,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    cursor: "pointer",
                                    borderRadius: 6
                                }}
                                onClick={()=>handleListenAudio()}
                            >
                                <AiFillSound size="20" />
                            </div>
                        </div>
                        <Swiper
                            pagination={true} 
                            modules={[Pagination, Navigation]} 
                            spaceBetween={20} 
                            initialSlide={0}
                            onSlideChange={(e) => setAudioUrl(card?.examples?.[e?.activeIndex]?.audioOld  || card?.examples?.[e?.activeIndex]?.audioUrl)}
                            loop={true}
                        >
                            {card?.examples?.map((example, index)=>(
                                <SwiperSlide key={index}>
                                    <div>
                                        <div style={{
                                            borderBottom: "1px dashed #F1F1F5",
                                            fontSize: 18,
                                            paddingTop: 10,
                                            paddingBottom: 10
                                        }}>
                                            {example?.character}
                                        </div>
                                        <div style={{
                                            borderBottom: "1px dashed #F1F1F5",
                                            fontSize: 16,
                                            paddingTop: 10,
                                            paddingBottom: 10
                                        }}>
                                            {example?.pinyin}
                                        </div>
                                        <div style={{
                                            fontSize: 16,
                                            paddingTop: 10,
                                            paddingBottom: 40
                                        }}>
                                            {example?.trans}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            }
        </Card>
    );
}

export default CardPreview;