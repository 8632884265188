import React, { useState } from 'react'
import styles from "./InApp.module.sass";
import DatePicker from "react-datepicker";
import { EditorState, convertToRaw, ConvertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import cn from 'classnames';
import TextInput from '../../../../components/TextInput';
import { useHistory, useParams, useLocation } from "react-router";
import Card from '../../../../components/Card';
import File from '../../../../components/File';
import { format } from "date-fns";
import Item from '../../../../components/Schedule/Item';
import Icon from '../../../../components/Icon';
import Editor from '../../../../components/Editor';
import { createNews, getNews, updateNews } from '../../../../api/news.service';
import { toast } from 'sonner';
import { useEffect } from 'react';

const LANGS = ['EN', 'ES', 'FR', 'RU', 'PT']

const InAppNotifications = () => {

    const history = useHistory()

    const { newId } = useParams();

    const [title, setTitle] = useState('')
    const [activeTitleTab, setActiveTitleTab] = useState(LANGS[0])
    const [activeContentTab, setActiveContentTab] = useState(LANGS[0])
    const [activeWebViewTab, setActiveWebViewTab] = useState(LANGS[0])
    const [activeLinkUrlTab, setActiveLinkUrlTab] = useState(LANGS[0])
    const [activeLinkTitleTab, setActiveLinkTitleTab] = useState(LANGS[0])

    const [visibleDate, setVisibleDate] = useState(false);
    const [visibleTime, setVisibleTime] = useState(false);

    const [startDate, setStartDate] = useState(new Date())
    const [startTime, setStartTime] = useState(new Date())

    const [descriptionState, setDescriptionState] = useState('')
    const [description, setDescription] = useState('')

    const [loading, setLoading] = useState(false)

    const [image, setImage] = useState('')

    const [date, setDate] = useState('')

    const [content, setContent] = useState('')

    const [link, setLink] = useState('')
    const [linkTitle, setLinkTitle] = useState('')

    const [objectTitle, setObjectTitle] = useState({})
    const [objectWebView, setObjectWebView] = useState({})
    const [objectLinkUrl, setObjectLinkUrl] = useState({})
    const [objectLinkTitle, setObjectLinkTitle] = useState({})

    const [objectTextarea, setObjectTextarea] = useState({
        ES: {
            state: EditorState.createEmpty(),
            trans: "",
        },
        EN: {
            state: EditorState.createEmpty(),
            trans: "",
        },
        FR: {
            state: EditorState.createEmpty(),
            trans: "",
        },
        RU: {
            state: EditorState.createEmpty(),
            trans: "",
        },
        PT: {
            state: EditorState.createEmpty(),
            trans: "",
        },
    })

    const handleGetNews = async () =>{
        let data = await getNews()
        console.log("data news", data)
        if(data?.data?.results?.length > 0){
            let _notification = data?.data?.results?.find(d => d?._id === newId)
            console.log("_notification", _notification)

            setLinkTitle(_notification?.linkTitle)
            setLink(_notification?.linkUrl)
            setDate(_notification?.scheduled)
            setImage(_notification?.photoUrl)
            setObjectTitle({
                ES: _notification?.title?.find(c => c?.lang === 'es')?.trans || '',
                EN: _notification?.title?.find(c => c?.lang === 'en')?.trans || '',
                FR: _notification?.title?.find(c => c?.lang === 'fr')?.trans || '',
                PT: _notification?.title?.find(c => c?.lang === 'pt')?.trans || '',
                RU: _notification?.title?.find(c => c?.lang === 'ru')?.trans || '',
            })

            setObjectWebView({
                ES: _notification?.webViewTrans?.find(c => c?.lang === 'es')?.trans || '',
                EN: _notification?.webViewTrans?.find(c => c?.lang === 'en')?.trans || '',
                FR: _notification?.webViewTrans?.find(c => c?.lang === 'fr')?.trans || '',
                PT: _notification?.webViewTrans?.find(c => c?.lang === 'pt')?.trans || '',
                RU: _notification?.webViewTrans?.find(c => c?.lang === 'ru')?.trans || '',
            })

            setObjectLinkUrl({
                ES: _notification?.linkUrlTrans?.find(c => c?.lang === 'es')?.trans || '',
                EN: _notification?.linkUrlTrans?.find(c => c?.lang === 'en')?.trans || '',
                FR: _notification?.linkUrlTrans?.find(c => c?.lang === 'fr')?.trans || '',
                PT: _notification?.linkUrlTrans?.find(c => c?.lang === 'pt')?.trans || '',
                RU: _notification?.linkUrlTrans?.find(c => c?.lang === 'ru')?.trans || '',
            })

            setObjectLinkTitle({
                ES: _notification?.linkTitleTrans?.find(c => c?.lang === 'es')?.trans || '',
                EN: _notification?.linkTitleTrans?.find(c => c?.lang === 'en')?.trans || '',
                FR: _notification?.linkTitleTrans?.find(c => c?.lang === 'fr')?.trans || '',
                PT: _notification?.linkTitleTrans?.find(c => c?.lang === 'pt')?.trans || '',
                RU: _notification?.linkTitleTrans?.find(c => c?.lang === 'ru')?.trans || '',
            })

            let _objectTextarea = {}

            LANGS.forEach((c)=>{
                const blocksFromHTML = convertFromHTML(_notification?.textArea?.find(f => f?.lang == String(c)?.toLowerCase())?.trans || '')
                const state = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap,
                )
                _objectTextarea[c] = {
                    state: EditorState.createWithContent(state),
                    trans: _notification?.textArea?.find(f => f?.lang == String(c)?.toLowerCase())?.trans || ''
                }
            })

            setObjectTextarea(_objectTextarea)
        }
    }

    useEffect(() => {
        if(newId){
            handleGetNews()
        }
    }, [newId])
    

    const handleClick = () => {
        setStartDate(null);
        setTimeout(() => setStartDate(new Date()), 10);
        setVisibleDate(false);
    };

    const stripHtml = (html)=> {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const handleTrad = async () =>{
        const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
        const authorizationHeader = { 'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).token}` }; // Crea el encabezado Authorization
    
        let TRANS_LANGS = ['es', 'pt', 'fr', 'ru']

        if(objectTextarea?.['EN']?.trans){

            for(let lang_ of TRANS_LANGS){
                console.log("lang_", lang_)
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({
                        title: objectTextarea?.['EN']?.trans,
                        lang: lang_
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        ...authorizationHeader // Agrega el encabezado Authorization a los encabezados de solicitud
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error en la respuesta de la API');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Respuesta de la API:', data);
                    let translation = data?.translations?.[0]?.text
                    if(translation){
                        let langUpper = lang_.toUpperCase()
                        const blocksFromHTML = convertFromHTML(translation || '')
                        const state = ContentState.createFromBlockArray(
                            blocksFromHTML.contentBlocks,
                            blocksFromHTML.entityMap,
                        )

                        setObjectTextarea(objectTextarea => {
                            return{
                                ...objectTextarea,
                                [langUpper]: {
                                    state: EditorState.createWithContent(state),
                                    trans: translation || ''
                                }
                            }
                        })
                    }
                })
                .catch(error => {
                    console.error('Error al llamar a la API:', error);
                });
            }

            toast.success('Translated Succesfully!')

        }

    }

    const handleTradTitle = async () =>{
        const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
        const authorizationHeader = { 'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).token}` }; // Crea el encabezado Authorization
    
        let TRANS_LANGS = ['es', 'pt', 'fr', 'ru']

        if(objectTitle?.['EN']){

            for(let lang_ of TRANS_LANGS){
                console.log("lang_", lang_)
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({
                        title: objectTitle?.['EN'],
                        lang: lang_
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        ...authorizationHeader // Agrega el encabezado Authorization a los encabezados de solicitud
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error en la respuesta de la API');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Respuesta de la API:', data);
                    let translation = data?.translations?.[0]?.text
                    if(translation){
                        let langUpper = lang_.toUpperCase()
                        setObjectTitle(objectTitle => {
                            return{
                                ...objectTitle,
                                [langUpper]: translation
                            }
                        })
                    }
                })
                .catch(error => {
                    console.error('Error al llamar a la API:', error);
                });
            }

            toast.success('Translated Succesfully!')

        }

    }

    const handleSendNotification = async (idEdit) =>{
        setLoading(true)
        let json = {
            type: "InApp",
            mailTemplate: "News",
            photoUrl: image,
            title: Object.entries(objectTitle).map((c)=>{
                return{
                    active: true,
                    lang: c?.[0]?.toLowerCase(),
                    trans: c?.[1]
                }
            }),
            webViewTrans: Object.entries(objectWebView).map((c)=>{
                return{
                    active: true,
                    lang: c?.[0]?.toLowerCase(),
                    trans: c?.[1]
                }
            }),
            linkUrlTrans: Object.entries(objectLinkUrl).map((c)=>{
                return{
                    active: true,
                    lang: c?.[0]?.toLowerCase(),
                    trans: c?.[1]
                }
            }),
            linkTitleTrans: Object.entries(objectLinkTitle).map((c)=>{
                return{
                    active: true,
                    lang: c?.[0]?.toLowerCase(),
                    trans: c?.[1]
                }
            }),
            textArea: Object.entries(objectTextarea).map((c)=>{
                return{
                    active: c?.[1]?.trans ? true : false,
                    lang: c?.[0]?.toLowerCase(),
                    trans: c?.[1]?.trans
                }
            }),
            scheduled: date,
            linkUrl: link,
            linkTitle: linkTitle
        }

        if(idEdit){
            json._id = idEdit
            let data = await updateNews(json)
            setLoading(false)
            console.log("json", json)
            console.log("data", data)
            if(data?.status === 200){
                toast.success('InApp Notification Updated successfully')
                history.push('/notifications')
            }else{
                toast.error(data?.data?.message)
            }
        }else{

            let data = await createNews(json)
            setLoading(false)
            console.log("json", json)
            console.log("data", data)
            if(data?.data?._id){
                toast.success('InApp Notification successfully')
                history.push('/notifications')
            }else{
                toast.error(data?.data?.message)
            }
        }

    }

    return (
        <Card>
            <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <label style={{fontSize: 14}}>
                        InApp title
                    </label>
                    <button disabled={!objectTitle?.['EN']} onClick={()=>handleTradTitle()} className={cn("button-stroke button-small", styles.button)} style={{marginLeft: 20}}>
                        Translate all
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 10}} className={cn(styles.nav, "tablet-hide")}>
                    {LANGS.map((x, index) => (
                        <button
                            className={cn(styles.link, {
                            [styles.active]: x === activeTitleTab,
                            })}
                            onClick={() => setActiveTitleTab(x)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
                <div style={{marginTop: 10}}>
                    <TextInput
                        type="text"
                        required
                        placeholder="InApp title here"
                        value={objectTitle?.[activeTitleTab] || ''}
                        onChange={(e)=>setObjectTitle(objectTitle => {return{...objectTitle, [activeTitleTab]: e.target.value}})}
                    />
                </div>
            </div>
            <div style={{ marginTop: 25 }}>
                {/* <File
                    className={styles.field}
                    title="Click or drop image"
                    label="Image"
                /> */}
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10}}>
                    <label style={{fontSize: 14}}>
                        Image
                    </label>
                    <a style={{color: 'blue', marginLeft: 15}} href="https://imgfz.com/es/" target="_blank">
                        Upload Image Free
                    </a>
                </div>
                <TextInput
                    type="text"
                    required
                    placeholder="image URL"
                    value={image}
                    onChange={(e)=>setImage(e.target.value)}
                />
            </div>
            <div style={{ marginTop: 25 }}>
                <label style={{fontSize: 14}}>
                    Scheduled
                </label>
                <div style={{marginTop: 10}}>
                    <input
                        className={styles.input}
                        value={date}
                        onChange={(e)=>setDate(e.target.value)}
                        type="datetime-local"
                    />
                </div>
            </div>
            <div style={{ marginTop: 25 }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <label style={{fontSize: 14}}>
                        InApp content
                    </label>
                    <button disabled={!objectTextarea?.['EN']?.trans} onClick={()=>handleTrad()} className={cn("button-stroke button-small", styles.button)} style={{marginLeft: 20}}>
                        Translate all
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 10}} className={cn(styles.nav, "tablet-hide")}>
                    {LANGS.map((x, index) => (
                        <button
                            className={cn(styles.link, {
                            [styles.active]: x === activeContentTab,
                            })}
                            onClick={() => setActiveContentTab(x)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
                <div style={{marginTop: 10}}>    
                    <Editor
                        onContentStateChange={setDescription}
                        state={objectTextarea?.[activeContentTab]?.state}
                        onChange={(e)=>setObjectTextarea(objectTextarea => {return{...objectTextarea, [activeContentTab]: {state: e, trans: draftToHtml(convertToRaw(e?.getCurrentContent())) } }})}
                        classEditor={styles.editor}
                    />
                </div>
            </div>
            <div style={{ marginTop: 25 }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <label style={{fontSize: 14}}>
                        WebView URL
                    </label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 10}} className={cn(styles.nav, "tablet-hide")}>
                    {LANGS.map((x, index) => (
                        <button
                            className={cn(styles.link, {
                            [styles.active]: x === activeWebViewTab,
                            })}
                            onClick={() => setActiveWebViewTab(x)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
                <div style={{marginTop: 10}}>
                    <TextInput
                        type="text"
                        required
                        placeholder="WebView Link URL"
                        value={objectWebView?.[activeWebViewTab] || ''}
                        onChange={(e)=>setObjectWebView(objectWebView => {return{...objectWebView, [activeWebViewTab]: e.target.value}})}
                    />
                </div>
            </div>
            <div style={{ marginTop: 25 }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <label style={{fontSize: 14}}>
                        Link URL
                    </label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 10}} className={cn(styles.nav, "tablet-hide")}>
                    {LANGS.map((x, index) => (
                        <button
                            className={cn(styles.link, {
                            [styles.active]: x === activeLinkUrlTab,
                            })}
                            onClick={() => setActiveLinkUrlTab(x)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
                <div style={{marginTop: 10}}>
                    <TextInput
                        type="text"
                        required
                        placeholder="Link here"
                        value={objectLinkUrl?.[activeLinkUrlTab] || ''}
                        onChange={(e)=>setObjectLinkUrl(objectLinkUrl => {return{...objectLinkUrl, [activeLinkUrlTab]: e.target.value}})}
                    />
                </div>
            </div>
            <div style={{ marginTop: 25 }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <label style={{fontSize: 14}}>
                        Link Title
                    </label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 10}} className={cn(styles.nav, "tablet-hide")}>
                    {LANGS.map((x, index) => (
                        <button
                            className={cn(styles.link, {
                            [styles.active]: x === activeLinkTitleTab,
                            })}
                            onClick={() => setActiveLinkTitleTab(x)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
                <div style={{marginTop: 10}}>
                    <TextInput
                        type="text"
                        required
                        placeholder="Link title"
                        value={objectLinkTitle?.[activeLinkTitleTab] || ''}
                        onChange={(e)=>setObjectLinkTitle(objectLinkTitle => {return{...objectLinkTitle, [activeLinkTitleTab]: e.target.value}})}
                    />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 35 }}>
                {/* <button className={cn("button-stroke", styles.button)}>
                    Preview
                </button> */}
                {newId ? (
                    <button onClick={()=>handleSendNotification(newId)} className={cn("button-primary", styles.button)}>
                        {loading ? 'Loading...' : <span>Update notification</span>}
                    </button>
                ) : (
                    <button onClick={()=>handleSendNotification()} disabled={loading} className={cn("button-primary", styles.button)}>
                        {loading ? 'Loading...' : <span>Save</span>}
                    </button>
                )}
            </div>
        </Card>
    );
}

export default InAppNotifications;