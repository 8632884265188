import React from 'react'
import TableComponent from '../../../components/TableComponent';
import { BiPlus } from 'react-icons/bi';



const Decks = ({ data, handleShowModalAddDeck, columns }) => {
    return (
        <TableComponent 
            data={data}
            columns={columns}
            isButton={true}
            buttonText="Add Deck"
            buttonIcon={<BiPlus size="24" />}
            buttonHandler={handleShowModalAddDeck}
            title="Decks"
        />
    );
}

export default Decks;