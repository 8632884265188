import { getApi, postApi, putApi } from './general.service'

export const postLogin = async data => {
  const user = await postApi('/auth/login', data)
  return user
}

export const recoverPass = async data => {
  const user = await getApi(`/auth/request-password?email=${data.email}`)
  return user
}

export const postRegister = async data => {
  const user = await postApi('/auth/register', data)
  return user
}

export const putUpdate = async data => {
  const user = await putApi('/user', data)
  console.log('putUpdate user--> ', user)
  return user
}

export const postOnboarding = async data => {
  const resp = await postApi('/onboard', data)
  return resp
}

export const putOnboarding = async data => {
  const resp = await putApi('/onboard', data)
  return resp
}
