import { deleteApi, getApi, postApi, putApi } from "./general.service";

export const createNews = async (data) => {
    const news = postApi("/news", data);
    return news;
};

export const createNewsPreview = async (data) => {
    const news = postApi("/news/preview/email/template", data);
    return news;
};

export const updateNews = async (data) => {
    const news = putApi("/news", data);
    return news;
};

export const getNews = async (data) => {
    const news = getApi("/news", data);
    return news;
};

export const deleteNews = async (data) => {
    const news = deleteApi(`/news?_id=${data?._id}`, data);
    return news;
};