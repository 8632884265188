import Icon from "../Icon";
import styles from "./SelectedTags.module.sass";

const SelectedTags = ({ tags, onTagRemove }) => {
  return (
    <div className={styles.container}>
      {tags.map((tag) => (
        <div className={styles.tag} key={tag.id}>
          <div className={styles.title}>{tag.title}</div>
          <div className={styles.icon} onClick={() => onTagRemove(tag)}>
            <Icon name="close" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectedTags;
