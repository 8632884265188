import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFeedback } from "../../api/feedback.service";

export const fetchFeedback = createAsyncThunk("feedback/fetchFeedback", async (data, { rejectWithValue }) => {
  const feedback = await getFeedback();
  console.log("****+feedback -> ", feedback);
  return feedback.data;
});

const fetchFeedbackReducer = {
  [fetchFeedback.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },
  [fetchFeedback.fulfilled]: (state, action) => {
    const feedback = action.payload.results;
    state.value = feedback;
    state.error = null;
    state.isLoading = false;
  },
  [fetchFeedback.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};


export const feedbackSlice = createSlice({
    name: "feedback",
    initialState: {
      value: [],
      error: null,
      editFeedbackValue: null,
      isLoading: false,
      isLoadingCreate: false,
    },
    reducers: {
      cleanFeedback: (state, action) => {
        state.value = [];
      },
    },
    extraReducers: {
      ...fetchFeedbackReducer,
    },
  });
  
  export const { cleanFeedback } = feedbackSlice.actions;
  
  export default feedbackSlice.reducer;