import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from './Users.module.sass'
import { toast } from 'sonner'
import TableComponent from '../../components/TableComponent';
import Modal from '../../components/Modal';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { fetchDeleteUser, fetchUpdateUser, fetchUsers } from '../../app/users/users.slice';
import TextInput from '../../components/TextInput';
import relativeTime from 'dayjs/plugin/relativeTime'
import Dropdown from '../../components/Dropdown';

dayjs.extend(relativeTime)

const columns = [
    { 
        header: 'User',
        accessor: 'name'
    },
    { 
        header: 'Level',
        accessor: 'level'
    },
    { 
        header: 'Time spent',
        accessor: 'totalTime'
    },
    { 
        header: 'Known cards',
        accessor: 'cardsKnowledge'
    },
    { 
        header: 'Last activity',
        accessor: 'lastActivity'
    },
    { 
        header: 'Actions',
        accessor: 'actions'
    },
]

const UsersComponent = () => {

    const { users } = useSelector((state) => state);

    const dispatch = useDispatch();

    const [dataTable, setDataTable] = useState([])

    const [isEditModal, setIsEditModal] = useState(false)
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState('')

    const [nextPageHash, setNextPageHash] = useState('')

    const [isLoading, setIsLoading] = useState(true)

    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userRole, setUserRole] = useState('')

    useEffect(() => {
        if(selectedUser){
            setUserName(selectedUser?.name)
            setUserEmail(selectedUser?.email)
            setUserRole(selectedUser?.role)
        }
    }, [selectedUser])
    

    console.log("users--->", users)

    const handleReturnDataTable = (data) =>{
        return data?.map((c)=>{
            return{
                name: {
                    data: c?.name,
                    dataComponent: (
                        <div>
                            <div style={{ fontWeight: 800, color: 'black'}}>
                                {c?.name}
                            </div>
                            <div style={{ fontSize: 14, color: 'gray'}}>
                                {c?.email}
                            </div>
                        </div>
                    )
                },
                level: {
                    data: c?.onboard?.level,
                    dataComponent: c?.onboard?.level
                },
                lastActivity: {
                    data: c?.lastUpdate,
                    dataComponent: <div>{dayjs(c?.lastUpdate)?.from(new Date())}</div>
                },
                totalTime: {
                    data: c?.stats?.totalTime,
                    dataComponent: c?.stats?.totalTime
                },
                cardsKnowledge: {
                    data: c?.stats?.cardsKnowledge,
                    dataComponent: c?.stats?.cardsKnowledge
                },
                actions: {
                    data: 'actions',
                    dataComponent: (
                        <div style={{ display: "flex", alignItems: "center"}}>
                            <button style={{color: 'blue'}} onClick={()=>{
                                setSelectedUser(c)
                                setIsEditModal(true)
                            }}>
                                Edit
                            </button>
                            <button style={{color: 'red', marginLeft: 20}} onClick={()=>{
                                setSelectedUser(c)
                                setIsDeleteModal(true)
                            }} >
                                Delete
                            </button>
                        </div>
                    )
                }
            }
        })
    }

    useEffect(async () => {
        await handleLoadMoreUsers()
        setIsLoading(false)
    }, [])

    const handleDeleteUser = async () =>{
        let result = await dispatch(fetchDeleteUser(selectedUser))
        if(result?.payload?._id){
            toast.success(`User ${selectedUser?.name} was deleted`)
            setIsDeleteModal(false)
        }
    }


    const handleUpdateUser = async () =>{

        let json = {
            _id: selectedUser?._id,
            name: userName,
            email: userEmail,
            role: userRole
        }

        await dispatch(fetchUpdateUser(json))
        await dispatch(fetchUsers())
        toast.success(`User ${selectedUser?.name} was updated`)
        setIsEditModal(false)
    }
    
    const handleSearchUser = (e) =>{
        dispatch(
            fetchUsers({
                take: 20,
                skip: 0,
                search: e,
            })
        );
    }

    useEffect(() => {
        if(users?.value){
            console.log("users---> value", users)
            let newData = handleReturnDataTable(users?.value)
            console.log("users---> newData", newData)
            if(newData?.length > 0){
                setDataTable(data => [
                ...newData
                ])
            }
        }
    }, [users])
    

    const handleLoadMoreUsers = async () =>{
        await dispatch(fetchUsers({
          take: 20,
          //search: "a",
        }))
    }

    return (
        <div>
            <Modal visible={isEditModal} onClose={() => setIsEditModal(false)}>
                <div>
                    <div style={{fontSize: 16, fontWeight: 'bold', marginTop: 10}}>
                        Edit user
                    </div>
                    <div>
                        <div style={{marginTop: 10}}>
                            <TextInput
                                name="Name"
                                type="text"
                                label="Name"
                                required
                                value={userName}
                                onChange={(e)=>setUserName(e.target.value)}
                            />
                        </div>
                        <div style={{marginTop: 10}}>
                            <TextInput
                                name="Email"
                                type="text"
                                label="Email"
                                required
                                value={userEmail}
                                onChange={(e)=>setUserEmail(e.target.value)}
                            />
                        </div>
                        <div style={{marginTop: 10}}>
                            <Dropdown
                                className={styles.field}
                                classDropdownLabel={styles.label}
                                setValue={setUserRole}
                                value={userRole}
                                options={[
                                    {label: 'ADMIN', value: 'ADMIN'},
                                    {label: 'USER', value: 'USER'},
                                    {label: 'TEACHER', value: 'TEACHER'}
                                ]}
                                label="Role"
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
                        <button style={{marginLeft: 20}} className={classNames("button", styles.button)} onClick={handleUpdateUser} >
                            Update
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal visible={isDeleteModal} onClose={() => setIsDeleteModal(false)}>
                <div>
                    <div style={{fontSize: 16, fontWeight: 'bold', marginTop: 10}}>
                        Delete user
                    </div>
                    <div style={{ fontSize: 14, color: 'gray', marginTop: 10}}>
                        Are you sure you want to delete {selectedUser?.name}?
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
                        <button style={{marginLeft: 20}} className={classNames("button", styles.button)} onClick={()=>setIsDeleteModal(false)} >
                            Cancel
                        </button>
                        <button style={{marginLeft: 20}} className={classNames("button-stroke-red", styles.button)} onClick={handleDeleteUser} >
                            Delete user
                        </button>
                    </div>
                </div>
            </Modal>
            {!isLoading &&
                <TableComponent
                    data={dataTable}
                    columns={columns}
                    handleSearchAPI={handleSearchUser}
                    onPageLoad={handleLoadMoreUsers}
                    title="Users"
                    searchPlaceholder="Search user..."
                    className="w-full"
                />
            }
        </div>
    );
}

export default UsersComponent;