import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../Checkbox";
import ModalProduct from "../../ModalProduct";
import Icon from "../../Icon";
import Actions from "../../Actions";
import Modal from "../../Modal";
import Schedule from "../../Schedule";
import Control from "./Control";
import { format } from "date-fns";

const Row = ({ item, value, onChange, onDelete = () => {} }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [visibleModalSchedule, setVisibleModalSchedule] = useState(false);

  const actions = [
    {
      title: "Schedule product",
      icon: "calendar",
      action: () => setVisibleModalSchedule(true),
    },
    {
      title: "Edit title & description",
      icon: "edit",
      action: () => console.log("Edit title & description"),
    },
    {
      title: "Delete forever",
      icon: "trash",
      action: () => onDelete(item),
    },
  ];

  return (
    <>
      <div className={styles.row}>
        {/* <div className={styles.col}>
          <Checkbox className={styles.checkbox} value={value} onChange={onChange} />
        </div> */}
        <div className={styles.col}>
          <div className={styles.item} onClick={() => setVisibleModalProduct(true)}>
            <div className={styles.preview}>
              <img
                srcSet={`${!item.avatarUrl?.includes('undefined') ? item.avatarUrl : "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"} 2x`}
                src={!item.avatarUrl?.includes('undefined') ? item.avatarUrl : "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"}
                alt="Product"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src="https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                }}
              />
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.title}</div>
              {/* <div className={styles.link}>{item.link}</div> */}
              <div className={styles.date}>
                <Icon name="clock" size="20" />{" "}
                {format(item.date ? new Date(item.date) : new Date(), "dd/MM/yyyy hh:mm")}
              </div>
              {/* {item.price > 0 ? (
                <div className={styles.price}>${item.price}</div>
              ) : (
                <div className={styles.empty}>${item.price}</div>
              )} */}
            </div>
          </div>
          <Actions className={styles.actions} classActionsHead={styles.actionsHead} items={actions} />
        </div>
        {/* <div className={styles.col}>
          {item.price > 0 ? (
            <div className={styles.price}>${item.price}</div>
          ) : (
            <div className={styles.empty}>${item.price}</div>
          )}
        </div> */}
        <div className={styles.col}>
          <div className={styles.item}>{item.title ? item.title : item.key}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.item}>{item.translations?.map((c)=>
            {
              if(c?.active){
                return(
                  <span style={{paddingRight: 11}}>
                    {c?.lang?.toUpperCase()}
                  </span>
                )
              }
            }
          )}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.item}>{item.level}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.item}>{item.cards ? item.cards.length : 0}</div>
        </div>
        <div className={styles.col}>
          {format(item.date ? new Date(item.date) : new Date(), "dd/MM/yyyy hh:mm")}
          <Control
            id={item._id}
            className={styles.control}
            startDate={startDate}
            setStartDate={setStartDate}
            startTime={startTime}
            setStartTime={setStartTime}
            onDelete={() => onDelete(item)}
          />
        </div>
      </div>
      {/* <ModalProduct visible={visibleModalProduct} onClose={() => setVisibleModalProduct(false)} /> */}
      <Modal visible={visibleModalSchedule} onClose={() => setVisibleModalSchedule(false)}>
        <Schedule startDate={startDate} setStartDate={setStartDate} startTime={startTime} setStartTime={setStartTime} />
      </Modal>
    </>
  );
};

export default Row;
