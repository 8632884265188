import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategory } from "../../api/category.service";

export const fetchCategory = createAsyncThunk("category/fetchCategory", async (data, { rejectWithValue }) => {
  const category = await getCategory();
  console.log("****+category -> ", category);
  return category.data;
});

const fetchCategoryReducer = {
  [fetchCategory.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },
  [fetchCategory.fulfilled]: (state, action) => {
    const category = action.payload.results;
    state.value = category;
    state.error = null;
    state.isLoading = false;
  },
  [fetchCategory.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};


export const categorySlice = createSlice({
    name: "category",
    initialState: {
      value: [],
      error: null,
      editCategoryValue: null,
      isLoading: false,
      isLoadingCreate: false,
    },
    reducers: {
      cleanCategory: (state, action) => {
        state.value = [];
      },
    },
    extraReducers: {
      ...fetchCategoryReducer,
    },
  });
  
  export const { cleanCategory } = categorySlice.actions;
  
  export default categorySlice.reducer;