import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./IncludedCards.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Tooltip from "../../../components/Tooltip";
import Checkbox from "../../../components/Checkbox";
import { WithContext as ReactTags } from "react-tag-input";
import DropdownSearch from "../../../components/DropdownSearch";
import { useDispatch, useSelector } from "react-redux";
import { fetchCards } from "../../../app/cards/cards.slice";
import SelectedTags from "../../../components/SelectedTags";
import { current } from "@reduxjs/toolkit";
import { parseSearchCardTags, parseSearchClassesTags } from "../../../utils";

const categoryOptions = ["Study", "Business", "Alf"];

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const IncludedClasses = ({ className, classRooms, setClassRooms }) => {
  const dispatch = useDispatch();
  const { user, classes } = useSelector((state) => state);

  //TODO REMOVE

  useEffect(() => {
    if (user.value) {
      dispatch(
        fetchCards({
          take: 100,
          skip: 0,
          //search: "a",
        })
      );
    }
  }, [user.value]);

  const handleAddCard = (value) => {
    setClassRooms((currentVal) => [...currentVal, value]);
  };

  const handleRemoveCard = (rCard) => {
    setClassRooms((currentVal) => currentVal.filter((c) => c.value !== rCard.value));
  };

  console.log(
    "classes --> ",
    classes.value.map((classe) => parseSearchClassesTags(classe))
  );

  return (
    <Card className={cn(styles.card, className)} title="Included classes" classTitle="title-blue">
      <div className={styles.images}>
        {/* <Dropdown
          className={styles.field}
          label="Category"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          value={category}
          setValue={setCategory}
          options={optionsCategory}
        /> */}
        <div className={styles.label}>
          Select student's classes that will get access to the deck
          <Tooltip
            className={styles.tooltip}
            title="Maximum 100 characters. No HTML or emoji allowed"
            icon="info"
            place="right"
          />
        </div>
        <div style={{ marginTop: 12 }}>
          <DropdownSearch
            className={styles.field}
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            value={"yep"}
            setValue={handleAddCard}
            options={classes.value.map((classe) => parseSearchClassesTags(classe))}
            onSearch={() => {}}
          />
        </div>
        <div style={{ marginTop: 12 }}>
          {classRooms.length === 0 ? (
            "No classes selected yet"
          ) : (
            <SelectedTags
              tags={classRooms.map((el, index) => ({
                value: el.value,
                id: `${index}`,
                title: el.label,
              }))}
              onTagRemove={handleRemoveCard}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default IncludedClasses;
