import React from 'react'
import { FiRepeat } from 'react-icons/fi'
import styles from './ContextInfo.module.sass'
import { useState } from 'react';
import { useEffect } from 'react';
import TextInput from '../../../../components/TextInput';
import File from '../../../../components/File';
import Switch from '../../../../components/Switch';

const ContextInfo = ({
    info,
    setInfo
}) => {

    const [characters, setCharacters] = useState('')
    const [pinyin, setPinyin] = useState('')
    const [tone, setTone] = useState('')

    const [tradES, setTradES] = useState('')
    const [tradEN, setTradEN] = useState('')
    const [tradFR, setTradFR] = useState('')
    const [tradRU, setTradRU] = useState('')
    const [tradPT, setTradPT] = useState('')

    const [activeES, setActiveES] = useState(false)
    const [activeEN, setActiveEN] = useState(false)
    const [activeFR, setActiveFR] = useState(false)
    const [activeRU, setActiveRU] = useState(false)
    const [activePT, setActivePT] = useState(false)

    const [pinyinActive, setPinyinActive] = useState(false)
    const [toneActive, setToneActive] = useState(false)

    const [file, setFile] = useState('')
    const [fileURL, setFileURL] = useState('')

    useEffect(() => {
        setCharacters(info?.character)
        setPinyin(info?.pinyin)
        setTone(info?.pinyinTone)

        setPinyinActive(info?.pinyinExample?.active)
        setToneActive(info?.pinyinToneExample?.active)

        setTradES(info?.translations?.filter(c => c?.lang === 'es')?.[0]?.trans)
        setTradEN(info?.translations?.filter(c => c?.lang === 'en')?.[0]?.trans)
        setTradFR(info?.translations?.filter(c => c?.lang === 'fr')?.[0]?.trans)
        setTradRU(info?.translations?.filter(c => c?.lang === 'ru')?.[0]?.trans)
        setTradPT(info?.translations?.filter(c => c?.lang === 'pt')?.[0]?.trans)

        setActiveES(info?.translations?.filter(c => c?.lang === 'es')?.[0]?.active)
        setActiveEN(info?.translations?.filter(c => c?.lang === 'en')?.[0]?.active)
        setActiveFR(info?.translations?.filter(c => c?.lang === 'fr')?.[0]?.active)
        setActiveRU(info?.translations?.filter(c => c?.lang === 'ru')?.[0]?.active)
        setActivePT(info?.translations?.filter(c => c?.lang === 'pt')?.[0]?.active)

        if(info?.fileExtra){
            setFile(info?.fileExtra)
            setFileURL(URL.createObjectURL(info?.fileExtra))
        }

        if(!info?.translations){
            setInfo({
                ...info,
                translations: [
                    {
                        lang: 'es',
                        active: true,
                        trans: ''
                    },
                    {
                        lang: 'en',
                        active: true,
                        trans: ''
                    },
                    {
                        lang: 'fr',
                        active: true,
                        trans: ''
                    },
                    {
                        lang: 'ru',
                        active: true,
                        trans: ''
                    },
                    {
                        lang: 'pt',
                        active: true,
                        trans: ''
                    },
                ]
            })
        }

    }, [info])


    const handleTrad = (lang) =>{
        const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
        const authorizationHeader = { 'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).token}` }; // Crea el encabezado Authorization

        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                title: info?.translations?.filter(c => c?.lang === 'en')?.[0]?.trans,
                lang
            }),
            headers: {
                'Content-Type': 'application/json',
                ...authorizationHeader // Agrega el encabezado Authorization a los encabezados de solicitud
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la respuesta de la API');
            }
            return response.json();
        })
        .then(data => {
            console.log('Respuesta de la API:', data);
            let translation = data?.translations?.[0]?.text
            if(translation){
                setInfo({
                    ...info,
                    translations: [...info?.translations?.filter(c => c?.lang !== lang?.toLowerCase()), {
                        lang: lang?.toLowerCase(),
                        active: info?.translations?.filter(c => c?.lang === lang?.toLowerCase())?.[0]?.active,
                        trans: translation
                    }]
                })
            }
        })
        .catch(error => {
            console.error('Error al llamar a la API:', error);
        });
    }

    const handleGetPinyins = (char) =>{
        const url = `${process.env.REACT_APP_API_URL}/translation/character`; // Reemplaza esta URL por la URL real de la API
        const authorizationHeader = { 'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).token}` }; // Crea el encabezado Authorization

        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                character: char
            }),
            headers: {
                'Content-Type': 'application/json',
                ...authorizationHeader // Agrega el encabezado Authorization a los encabezados de solicitud
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la respuesta de la API');
            }
            return response.json();
        })
        .then(data => {
            console.log('Respuesta de la API:', data);
            if(data?.pinyin){
                setInfo({
                    ...info,
                    pinyin: data?.pinyin,
                    pinyinTone: data?.pinyinTone
                })
            }
        })
        .catch(error => {
            console.error('Error al llamar a la API:', error);
        });
    }

    
    const handleUploadFile = (e) =>{
        setFile(e.target.files?.[0])
        setFileURL(URL.createObjectURL(e.target.files?.[0]))
        setInfo({
            ...info,
            fileExtra: e.target.files?.[0]
        })
    }

    return (
        <div>
            <TextInput
                name="Characters"
                type="text"
                label="Characters"
                tooltip="Small description"
                required
                value={characters}
                onChange={(e)=>{
                    setInfo({
                        ...info,
                        character: e.target.value
                    })
                }}
            />
            <div style={{ display: 'flex', marginTop: 25 }} >
                <div style={{ width: '100%', marginRight: 10, position: 'relative' }}>
                    <TextInput
                        name="Pinyin"
                        type="text"
                        label="Pinyin"
                        tooltip="Small description"
                        required
                        value={pinyin}
                        onChange={(e)=>{
                            setInfo({
                                ...info,
                                pinyin: e.target.value
                            })
                        }}
                    />
                    <div style={{position: 'absolute', right: 20, top:20, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button onClick={()=>handleGetPinyins(characters)} style={{ marginRight: 20 }}>
                            <FiRepeat size="20" />
                        </button>
                        <Switch 
                            value={pinyinActive}
                            onChange={() => {
                                setInfo({
                                    ...info,
                                    pinyinExample: {
                                        ...info.pinyinExample,
                                        active: !pinyinActive
                                    }
                                })
                            }}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', marginLeft: 10, position: 'relative' }}>
                    <TextInput
                        name="Tone"
                        type="text"
                        label="Tone"
                        tooltip="Small description"
                        required
                        value={tone}
                        onChange={(e)=>{
                            setInfo({
                                ...info,
                                pinyinTone: e.target.value
                            })
                        }}
                    />
                    <div style={{position: 'absolute', right: 20, top:20, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button onClick={()=>handleGetPinyins(characters)} style={{ marginRight: 20 }}>
                            <FiRepeat size="20" />
                        </button>
                        <Switch 
                            value={toneActive}
                            onChange={() => {
                                setInfo({
                                    ...info,
                                    pinyinToneExample: {
                                        ...info.pinyinToneExample,
                                        active: !toneActive
                                    }
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.inputsField}>
                <div style={{
                    position: 'relative'
                }}>
                    <TextInput
                        name="Tone"
                        type="text"
                        tooltip="Small description"
                        required
                        currency="EN"
                        defaultValue={tradEN}
                        onChange={(e)=>{
                            setInfo({
                                ...info,
                                translations: [...info?.translations?.filter(c => c?.lang !== 'en'), {
                                    trans: e.target.value,
                                    lang: "en",
                                    active: info?.translations?.filter(c => c?.lang === 'en')?.[0]?.active
                                }]
                            })
                        }}
                    />
                    {/* <div style={{ position: 'absolute', right: 20, top:0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button onClick={()=>handleTrad('EN')} style={{ marginRight: 20 }}>
                            <FiRepeat size="20" />
                        </button>
                        <Switch 
                            value={activeEN}
                            onChange={() => {
                                setInfo({
                                    ...info,
                                    translations: [...info?.translations?.filter(c => c?.lang !== 'en'), {
                                        lang: "en",
                                        active: info?.translations?.filter(c => c?.lang === 'en')?.[0]?.active ? false : true,
                                        trans: info?.translations?.filter(c => c?.lang === 'en')?.[0]?.trans
                                    }]
                                })
                            }}
                        />
                    </div> */}
                </div>
                <div style={{
                    position: 'relative'
                }}>
                    <TextInput
                        name="Tone"
                        type="text"
                        tooltip="Small description"
                        required
                        currency="ES"
                        defaultValue={tradES}
                        onChange={(e)=>{
                            setInfo({
                                ...info,
                                translations: [...info?.translations?.filter(c => c?.lang !== 'es'), {
                                    trans: e.target.value,
                                    lang: "es",
                                    active: info?.translations?.filter(c => c?.lang === 'es')?.[0]?.active
                                }]
                            })
                        }}
                    />
                    <div style={{ position: 'absolute', right: 20, top:0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button onClick={()=>handleTrad('ES')} style={{ marginRight: 20 }}>
                            <FiRepeat size="20" />
                        </button>
                        <Switch 
                            value={activeES}
                            onChange={() => {
                                setInfo({
                                    ...info,
                                    translations: [...info?.translations?.filter(c => c?.lang !== 'es'), {
                                        trans: info?.translations?.filter(c => c?.lang === 'es')?.[0]?.trans,
                                        lang: "es",
                                        active: info?.translations?.filter(c => c?.lang === 'es')?.[0]?.active ? false : true,
                                    }]
                                })
                            }}
                        />
                    </div>
                </div>
                <div style={{
                    position: 'relative'
                }}>
                    <TextInput
                        name="Tone"
                        type="text"
                        tooltip="Small description"
                        required
                        currency="FR"
                        defaultValue={tradFR}
                        onChange={(e)=>{
                            setInfo({
                                ...info,
                                translations: [...info?.translations?.filter(c => c?.lang !== 'fr'), {
                                    trans: e.target.value,
                                    lang: "fr",
                                    active: info?.translations?.filter(c => c?.lang === 'fr')?.[0]?.active,
                                }]
                            })
                        }}
                    />
                    <div style={{ position: 'absolute', right: 20, top:0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button onClick={()=>handleTrad('FR')} style={{ marginRight: 20 }}>
                            <FiRepeat size="20" />
                        </button>
                        <Switch 
                            value={activeFR}
                            onChange={() => {
                                setInfo({
                                    ...info,
                                    translations: [...info?.translations?.filter(c => c?.lang !== 'fr'), {
                                        trans: info?.translations?.filter(c => c?.lang === 'fr')?.[0]?.trans,
                                        lang: "fr",
                                        active: info?.translations?.filter(c => c?.lang === 'fr')?.[0]?.active ? false : true,
                                    }]
                                })
                            }}
                        />
                    </div>
                </div>
                <div style={{
                    position: 'relative'
                }}>
                    <TextInput
                        name="Tone"
                        type="text"
                        tooltip="Small description"
                        required
                        currency="RU"
                        defaultValue={tradRU}
                        onChange={(e)=>{
                            setInfo({
                                ...info,
                                translations: [...info?.translations?.filter(c => c?.lang !== 'ru'), {
                                    trans: e.target.value,
                                    lang: "ru",
                                    active: info?.translations?.filter(c => c?.lang === 'ru')?.[0]?.active,
                                }]
                            })
                        }}
                    />
                    <div style={{ position: 'absolute', right: 20, top:0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button onClick={()=>handleTrad('RU')} style={{ marginRight: 20 }}>
                            <FiRepeat size="20" />
                        </button>
                        <Switch 
                            value={activeRU}
                            onChange={() => {
                                setInfo({
                                    ...info,
                                    translations: [...info?.translations?.filter(c => c?.lang !== 'ru'), {
                                        trans: info?.translations?.filter(c => c?.lang === 'ru')?.[0]?.trans,
                                        lang: "ru",
                                        active: info?.translations?.filter(c => c?.lang === 'ru')?.[0]?.active ? false : true,
                                    }]
                                })
                            }}
                        />
                    </div>
                </div>
                <div style={{
                    position: 'relative'
                }}>
                    <TextInput
                        name="Tone"
                        type="text"
                        tooltip="Small description"
                        required
                        currency="PT"
                        defaultValue={tradPT}
                        onChange={(e)=>{
                            setInfo({
                                ...info,
                                translations: [...info?.translations?.filter(c => c?.lang !== 'pt'), {
                                    trans: e.target.value,
                                    lang: "pt",
                                    active: info?.translations?.filter(c => c?.lang === 'pt')?.[0]?.active,
                                }]
                            })
                        }}
                    />
                    <div style={{ position: 'absolute', right: 20, top:0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button onClick={()=>handleTrad('PT')} style={{ marginRight: 20 }}>
                            <FiRepeat size="20" />
                        </button>
                        <Switch 
                            value={activePT}
                            onChange={() => {
                                setInfo({
                                    ...info,
                                    translations: [...info?.translations?.filter(c => c?.lang !== 'pt'), {
                                        trans: info?.translations?.filter(c => c?.lang === 'pt')?.[0]?.trans,
                                        lang: "pt",
                                        active: info?.translations?.filter(c => c?.lang === 'pt')?.[0]?.active ? false : true,
                                    }]
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            {(info?.videoOld || info?.videoUrl || file) ? (
                    <div style={{ marginTop: 25, backgroundColor: '#F4F4F4', borderRadius: '12px', padding: '12px', display: 'flex', position: 'relative' }}>
                        <video src={info?.videoOld || info?.videoUrl || fileURL} style={{ width: '350px', borderRadius: '12px'}} controls />
                        <div style={{ marginLeft: 20, fontSize: 20 }}>
                            {file?.name || "Video .mp4"}
                        </div>
                        <button style={{color: 'red', position: "absolute", top: '47%', right: 20}} onClick={()=>{
                            setFile('')

                            setInfo({
                                ...info,
                                videoOld: '',
                                videoUrl: '',
                                fileExtra: ''
                            })
                        }} >
                            Delete
                        </button>
                    </div>
                ) : (
                <div style={{ marginTop: 25 }}>
                    <File
                        className={styles.field}
                        onChange={handleUploadFile}
                        title="Click or drop video"
                        label="Upload video"
                        tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    />
                </div>
            )}
        </div>
    );
}

export default ContextInfo;