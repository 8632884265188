import React, { useEffect, useState } from "react";
import styles from "./NewClasse.module.sass";
import IncludedStudents from "./IncludedStudents";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreateClasse, fetchEditClasse, fetchUpdateClasse } from "../../app/classes/classes.slice";
import Loader from "../../components/Loader";
import { useHistory, useParams } from "react-router";

const NewClasse = () => {
  const { classes } = useSelector((state) => state);

  const [title, setTitle] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const history = useHistory();
  const { classeId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (classes.editClasseValue) {
      const editClasse = classes.editClasseValue;
      setTitle(editClasse.title);
      setSelectedStudents(editClasse.students);
    }
  }, [classes.editClasseValue]);

  useEffect(() => {
    if (classeId) {
      dispatch(fetchEditClasse({ _id: classeId }));
    }
  }, [classeId]);

  const handleSave = () => {
    const postData = {
      title,
      students: selectedStudents,
    };
    if (classeId) {
      postData._id = classeId;
      dispatch(fetchUpdateClasse(postData));
    } else {
      dispatch(fetchCreateClasse(postData));
    }
    history.push(`/classes`);
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <IncludedStudents
            className={styles.card}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
            title={title}
            setTitle={setTitle}
          />
        </div>
      </div>
      <div className={cn("button", styles.button)} onClick={handleSave}>
        {classes.isLoadingCreate ? <Loader className={styles.loader} /> : <span>Save changes</span>}
      </div>
    </>
  );
};

export default NewClasse;
