import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import styles from "./AddBugModal.module.sass";
import Modal from '../../../components/Modal';
import DropdownSearch from '../../../components/DropdownSearch';
import { fetchCards } from '../../../app/cards/cards.slice';
import { parseSearchCardTags } from '../../../utils';
import { AVAILABLE_LANGS } from '../../../utils/constants';
import TextInput from '../../../components/TextInput';
import Dropdown from '../../../components/Dropdown';
import Loader from '../../../components/Loader';
import classNames from 'classnames';
import { createBug } from '../../../api/bug.service';
import { fetchBug } from '../../../app/bugs/bugs.slice';
import { toast } from 'sonner';

const AddBugModal = ({ visible, setVisible, selectedCardProp }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { bugs, cards } = useSelector((state) => state);

    const [selectedCard, setSelectedCard] = useState('')
    const [bugType, setBugType] = useState('')
    const [reason, setReason] = useState('')
    const [system, setSystem] = useState('')
    const [lang, setLang] = useState('')

    const handleSearch = (term) => {
        dispatch(
          fetchCards({
            take: 100,
            skip: 0,
            search: term,
          })
        );
    };

    const handleSetValue = (e) =>{
        setSelectedCard(e)
    }

    useEffect(() => {
        if(selectedCardProp){
            setSelectedCard({label: `${selectedCardProp?.meaning?.character} - ${selectedCardProp?.meaning?.trans}` ,value: selectedCardProp?._id})
        }
    }, [selectedCardProp])
    

    const handleSave = async () =>{
        let json = {
            active: true,
            card: selectedCard?.value,
            bugType,
            reason,
            system: String(navigator.userAgent),
            lang
        }
        const resp = await createBug(json)

        if(resp?.data?.card){
            dispatch(fetchBug());
            setVisible(false)
            toast.success('Bug created successfully', {
                action: {
                  label: 'See Card',
                  onClick: () => history.push(`/card/edit/${resp?.data?.card}`)
                },
            })
        }
    }

    return (
        <Modal outerClassName={styles.outer} visible={visible} onClose={() => setVisible(false)} >
            <div style={{marginTop: 0}}>
                <h2>
                    Report Bug
                </h2>
                <div className={styles.spaceYinputs} style={{marginTop: 20}}>
                    <div>
                        {!selectedCardProp &&
                            <>
                                <div className={styles.label}>
                                    Select card
                                </div>
                                <DropdownSearch
                                    className={styles.field}
                                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                                    value={selectedCard}
                                    placeholder="Search Cards"
                                    setValue={handleSetValue}
                                    options={cards.value.map((card) => parseSearchCardTags(card))}
                                    isLoading={cards.isLoading}
                                    timeoutSearch={500}
                                    onSearch={handleSearch}
                                />
                            </>
                        }
                        <div style={{ marginTop: 20 }}>
                            <div style={{fontSize: 14}}>
                                Card Selected
                            </div>
                            <div style={{fontSize: 16, marginTop: 10}}>
                                {selectedCard?.label || 'none'}
                            </div>
                        </div>
                    </div>
                    <Dropdown
                        className={styles.field}
                        classDropdownLabel={styles.label}
                        setValue={setBugType}
                        value={bugType}
                        options={[
                            {label: 'Translation', value: 'Translation'},
                            {label: 'AppError', value: 'AppError'},
                            {label: 'VideoMismatch', value: 'VideoMismatch'},
                            {label: 'WrongContextExample', value: 'WrongContextExample'},
                            {label: 'WordTranslationError', value: 'WordTranslationError'},
                            {label: 'Context1TransError', value: 'Context1TransError'},
                            {label: 'Context2TransError', value: 'Context2TransError'},
                            {label: 'PinyinError', value: 'PinyinError'},
                            {label: 'MissVideo', value: 'MissVideo'},
                        ]}
                        label="Bug Type"
                    />
                    <TextInput
                        name="Reason"
                        type="text"
                        required
                        label="Reason"
                        value={reason}
                        onChange={(e)=>setReason(e.target.value)}
                    />
                    <Dropdown
                        className={styles.field}
                        classDropdownLabel={styles.label}
                        setValue={setLang}
                        value={lang}
                        options={AVAILABLE_LANGS?.map((lang)=>{
                            return{
                                label: lang, value: lang
                            }
                        })}
                        label="Language"
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <button disabled={!selectedCard?.value || !bugType || !reason || !lang} style={{marginLeft: 20}} className={classNames("button", styles.button)} onClick={handleSave} >
                            {bugs.isLoadingCreate ? <Loader className={styles.loader} /> : <span>Save changes</span>}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default AddBugModal;