import React, { useEffect, useState } from 'react'
import styles from "./Push.module.sass";
import DatePicker from "react-datepicker";
import cn from 'classnames';
import TextInput from '../../../../components/TextInput';
import Card from '../../../../components/Card';
import { useHistory, useParams } from "react-router";
import File from '../../../../components/File';
import { format } from "date-fns";
import Item from '../../../../components/Schedule/Item';
import Icon from '../../../../components/Icon';
import Editor from '../../../../components/Editor';
import { createNews, getNews, updateNews } from '../../../../api/news.service';
import { toast } from 'sonner';

const LANGS = ['EN', 'ES', 'FR', 'RU', 'PT']

const PushNotifications = () => {

    const { newId } = useParams();

    const history = useHistory()

    const [title, setTitle] = useState('')
    const [activeTitleTab, setActiveTitleTab] = useState(LANGS[0])
    const [activeContentTab, setActiveContentTab] = useState(LANGS[0])

    const [visibleDate, setVisibleDate] = useState(false);
    const [visibleTime, setVisibleTime] = useState(false);

    const [startDate, setStartDate] = useState(new Date())
    const [startTime, setStartTime] = useState(new Date())

    const [descriptionState, setDescriptionState] = useState('')
    const [description, setDescription] = useState('')

    const [loading, setLoading] = useState(false)

    const [content, setContent] = useState('')

    const [link, setLink] = useState('')

    const handleClick = () => {
        setStartDate(null);
        setTimeout(() => setStartDate(new Date()), 10);
        setVisibleDate(false);
    };

    const [objectTitle, setObjectTitle] = useState({})
    const [objectTextarea, setObjectTextarea] = useState({
        ES: '',
        EN: '',
        FR: '',
        RU: '',
        PT: '',
    })

    const [date, setDate] = useState('')

    const handleGetNews = async () =>{
        let data = await getNews()
        console.log("data news", data)
        if(data?.data?.results?.length > 0){
            let _notification = data?.data?.results?.find(d => d?._id === newId)
            console.log("_notification", _notification)
            setLink(_notification?.linkUrl)
            setDate(_notification?.scheduled)
            setObjectTitle({
                ES: _notification?.title?.find(c => c?.lang === 'es')?.trans || '',
                EN: _notification?.title?.find(c => c?.lang === 'en')?.trans || '',
                FR: _notification?.title?.find(c => c?.lang === 'fr')?.trans || '',
                PT: _notification?.title?.find(c => c?.lang === 'pt')?.trans || '',
                RU: _notification?.title?.find(c => c?.lang === 'ru')?.trans || '',
            })

            setObjectTextarea({
                ES: _notification?.textArea?.find(c => c?.lang === 'es')?.trans || '',
                EN: _notification?.textArea?.find(c => c?.lang === 'en')?.trans || '',
                FR: _notification?.textArea?.find(c => c?.lang === 'fr')?.trans || '',
                PT: _notification?.textArea?.find(c => c?.lang === 'pt')?.trans || '',
                RU: _notification?.textArea?.find(c => c?.lang === 'ru')?.trans || '',
            })
            
        }
    }

    useEffect(() => {
        if(newId){
            handleGetNews()
        }
    }, [newId])


    const handleSendNotification = async () =>{
        setLoading(true)
        let json = {
            type: "Push",
            mailTemplate: "News",
            photoUrl: "",
            title: Object.entries(objectTitle).map((c)=>{
                return{
                    active: true,
                    lang: c?.[0]?.toLowerCase(),
                    trans: c?.[1]
                }
            }),
            textArea: Object.entries(objectTextarea).map((c)=>{
                return{
                    active: c?.[1] ? true : false,
                    lang: c?.[0]?.toLowerCase(),
                    trans: c?.[1]
                }
            }),
            scheduled: date,
            linkUrl: link,
            linkTitle: ""
        }

        if(newId){
            json._id = newId
            let data = await updateNews(json)
            setLoading(false)
            console.log("json", json)
            console.log("data", data)
            if(data?.status === 200){
                toast.success('Push Notification Updated successfully')
                history.push('/notifications')
            }else{
                toast.error(data?.data?.message)
            }
        }else{
            let data = await createNews(json)
            setLoading(false)
            console.log("json", json)
            console.log("data", data)
            if(data?.data?._id){
                toast.success('Mail Added successfully')
                history.push('/notifications')
            }else{
                toast.error(data?.message)
            }
        }

    }

    const handleTrad = async () =>{
        const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
        const authorizationHeader = { 'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).token}` }; // Crea el encabezado Authorization
    
        let TRANS_LANGS = ['es', 'pt', 'fr', 'ru']

        if(objectTextarea?.['EN']){

            for(let lang_ of TRANS_LANGS){
                console.log("lang_", lang_)
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({
                        title: objectTextarea?.['EN'],
                        lang: lang_
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        ...authorizationHeader // Agrega el encabezado Authorization a los encabezados de solicitud
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error en la respuesta de la API');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Respuesta de la API:', data);
                    let translation = data?.translations?.[0]?.text
                    if(translation){
                        let langUpper = lang_.toUpperCase()
                        setObjectTextarea(objectTextarea => {
                            return{
                                ...objectTextarea,
                                [langUpper]: translation
                            }
                        })
                    }
                })
                .catch(error => {
                    console.error('Error al llamar a la API:', error);
                });
            }

            toast.success('Translated Succesfully!')

        }

    }

    const handleTradTitle = async () =>{
        const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
        const authorizationHeader = { 'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).token}` }; // Crea el encabezado Authorization
    
        let TRANS_LANGS = ['es', 'pt', 'fr', 'ru']

        if(objectTitle?.['EN']){

            for(let lang_ of TRANS_LANGS){
                console.log("lang_", lang_)
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({
                        title: objectTitle?.['EN'],
                        lang: lang_
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        ...authorizationHeader // Agrega el encabezado Authorization a los encabezados de solicitud
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error en la respuesta de la API');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Respuesta de la API:', data);
                    let translation = data?.translations?.[0]?.text
                    if(translation){
                        let langUpper = lang_.toUpperCase()
                        setObjectTitle(objectTitle => {
                            return{
                                ...objectTitle,
                                [langUpper]: translation
                            }
                        })
                    }
                })
                .catch(error => {
                    console.error('Error al llamar a la API:', error);
                });
            }

            toast.success('Translated Succesfully!')

        }

    }
    

    return (
        <Card>
            <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <label style={{fontSize: 14}}>
                        Push title
                    </label>
                    <button disabled={!objectTitle?.['EN']} onClick={()=>handleTradTitle()} className={cn("button-stroke button-small", styles.button)} style={{marginLeft: 20}}>
                        Translate all
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 10}} className={cn(styles.nav, "tablet-hide")}>
                    {LANGS.map((x, index) => (
                        <button
                            className={cn(styles.link, {
                            [styles.active]: x === activeTitleTab,
                            })}
                            onClick={() => setActiveTitleTab(x)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
                <div style={{marginTop: 10}}>
                    <TextInput
                        type="text"
                        required
                        placeholder="Push title here"
                        value={objectTitle?.[activeTitleTab] || ''}
                        onChange={(e)=>setObjectTitle(objectTitle => {return{...objectTitle, [activeTitleTab]: e.target.value}})}
                    />
                </div>
            </div>
            <div style={{ marginTop: 25 }}>
                <label style={{fontSize: 14}}>
                    Scheduled
                </label>
                <div style={{marginTop: 10}}>
                    <input
                        className={styles.input}
                        value={date}
                        onChange={(e)=>setDate(e.target.value)}
                        type="datetime-local"
                    />
                </div>
            </div>
            <div style={{ marginTop: 25 }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <label style={{fontSize: 14}}>
                        Push content
                    </label>
                    <button disabled={!objectTextarea?.['EN']} onClick={()=>handleTrad()} className={cn("button-stroke button-small", styles.button)} style={{marginLeft: 20}}>
                        Translate all
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 10}} className={cn(styles.nav, "tablet-hide")}>
                    {LANGS.map((x, index) => (
                        <button
                            className={cn(styles.link, {
                            [styles.active]: x === activeContentTab,
                            })}
                            onClick={() => setActiveContentTab(x)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
                <div style={{marginTop: 10}}>    
                    <textarea
                        rows="30"
                        style={{height: '200px'}}
                        className={styles.input}
                        placeholder="Write here..."
                        value={objectTextarea?.[activeContentTab] || ''}
                        onChange={(e)=>objectTextarea?.[activeContentTab]?.length < 200 && setObjectTextarea(objectTextarea => {return{...objectTextarea, [activeContentTab]: e.target.value}})}
                        onKeyUp={(e)=>(objectTextarea?.[activeContentTab]?.length >= 200 && e?.keyCode === 8) && setObjectTextarea(objectTextarea => {return{...objectTextarea, [activeContentTab]: e.target.value.slice(0, -1)}})}                 
                    ></textarea>
                    <span>
                        {`${objectTextarea?.[activeContentTab]?.length || 0}/200 chars`}
                    </span>
                </div>
            </div>
            <div style={{marginTop: 25}}>
                <TextInput
                    type="text"
                    required
                    placeholder="Link here"
                    value={link}
                    label="Push link"
                    onChange={(e)=>setLink(e.target.value)}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 35 }}>
                {/* <button className={cn("button-stroke", styles.button)}>
                    Preview
                </button> */}
                <button onClick={handleSendNotification} disabled={loading} className={cn("button-primary", styles.button)}>
                    {loading ? 'Loading...' : <span>Save</span>}
                </button>
            </div>
        </Card>
    );
}

export default PushNotifications;