import { deleteApi, getApi, postApi, putApi } from "./general.service";

export const getClassifications = async (data) => {
  const classification = getApi("/admin/classification", data);
  return classification;
};

export const getClassification = async (data) => {
  const classification = getApi(`/admin/classification/${data._id}`, data);
  return classification;
};

export const createClassification = async (data) => {
  const classification = postApi("/admin/classification", data);
  return classification;
};

export const updateClassification = async (data) => {
  const classification = putApi("/admin/classification", data);
  return classification;
};

export const deleteClassification = async (data) => {
  const classification = deleteApi(`/admin/classification?_id=${data._id}`, data);
  return classification;
};
