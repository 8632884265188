import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Invitations.module.sass";
import Card from "../../../components/Card";
import SelectedTags from "../../../components/SelectedTags";
import TextInput from "../../../components/TextInput";


const KeyCodes = {
  comma: 188,
  enter: 13,
};
const Invitations = ({ className, invitations, setInvitations }) => {
  
    const [email, setEmail] = useState('')


    const handleAddCard = () => {
        setInvitations((currentVal) => [...currentVal, email]);
        setEmail('')
    };

    const handleRemoveCard = (tag) => {
        setInvitations((currentVal) => currentVal.filter((c) => c !== tag.value));
    };


  return (
    <Card className={cn(styles.card, className)} title="Invitations" classTitle="title-blue">
      <div className={styles.images}>
        {/* <Dropdown
          className={styles.field}
          label="Category"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          value={category}
          setValue={setCategory}
          options={optionsCategory}
        /> */}
        <div className={styles.label}>
            Write the email of the people you want to invite (<code>Enter</code> on finish)
        </div>
        <div style={{ marginTop: 12 }}>
            <TextInput
                name="email"
                type="text"
                tooltip="Small description"
                placeholder="example@mail.com"
                required
                value={email}
                onKeyUp={(e)=>e.keyCode === KeyCodes['enter'] && handleAddCard()}
                onChange={(e)=>setEmail(e.target.value)}
            />
        </div>
        <div style={{ marginTop: 12 }}>
          {invitations.length === 0 ? (
            "Press 'Enter' when putting the email"
          ) : (
            <SelectedTags
              tags={invitations.map((el, index) => ({
                value: el,
                id: `${index}`,
                title: el,
              }))}
              onTagRemove={handleRemoveCard}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default Invitations;
