import React from "react";
import cn from "classnames";
import styles from "./Preview.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";

const Preview = ({ visible, onClose, avatarUrl, file, title }) => {
  return (
    <div className={cn(styles.preview, { [styles.visible]: visible })}>
      <button className={styles.close} onClick={onClose}>
        <Icon name="close" size="24" />
      </button>
      <Card
        className={styles.card}
        classCardHead={styles.head}
        title="Deck preview"
        classTitle="title-blue"
      >
        <div className={styles.body}>
          <div className={styles.photo}>
            {(file || avatarUrl) &&
              <img src={file || avatarUrl} alt="Product" />
            }
          </div>
          <div className={styles.line}>
            <div className={styles.title}>
              {title}
            </div>
            {/* <div className={styles.price}>$98</div> */}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Preview;
