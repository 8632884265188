import React, { useEffect, useState } from 'react'
import cn from "classnames";
import styles from "./Notifications.module.sass";
import TableComponent from '../../components/TableComponent';
import dayjs from 'dayjs';
import { BiEdit, BiPlus, BiShow, BiTrash } from 'react-icons/bi';
import { useHistory } from "react-router";
import { deleteNews, getNews, updateNews } from '../../api/news.service';
import { toast } from 'sonner';
import Modal from '../../components/Modal';

const navigation = ["All", "Mail", "Push", "InApp"]

const columns = [
    { 
        header: 'Date',
        accessor: 'date'
    },
    { 
        header: 'Type',
        accessor: 'type'
    },
    { 
        header: 'Detail',
        accessor: 'detail'
    },
    // { 
    //     header: 'Delivered',
    //     accessor: 'delivered'
    // },
    // { 
    //     header: 'Read',
    //     accessor: 'read'
    // },
    { 
        header: 'Status',
        accessor: 'draft'
    },
    { 
        header: '',
        accessor: 'actions'
    },
]


const Notifications = () => {

    const history = useHistory();
    const [activeTab, setActiveTab] = useState(navigation[0])

    const [dataTable, setDataTable] = useState([])

    const [visiblePreview, setVisiblePreview] = useState(false)
    const [visibleDelete, setVisibleDelete] = useState(false)

    const [notificationSelected, setNotificationSelected] = useState('')

    const [visibleSend, setVisibleSend] = useState(false)

    const [loading, setLoading] = useState(false)


    const handleSendNew = async (noti) =>{
        setLoading(true)
        let json = {
            ...noti,
            state: 'Sent'
        }
        let data = await updateNews(json)
        setVisibleSend(false)
        setLoading(false)
        console.log("data sent", data)
        if(data?.status === 200){
            toast.success('Sent correctly!')
            handleGetNews()
        }else{
            toast.error(data?.data?.message)
        }
    }
    const handleGetNews = async () =>{
        let data = await getNews()
        console.log("data", data)

        if(data?.data?.results){
            setDataTable(data?.data?.results.reverse().map((c)=>{
                return {
                    date: {
                        data: 'data',
                        dataComponent: (
                            <div>
                                <div style={{fontWeight: 'bold', color: 'black'}}>
                                    {dayjs(c?.date).format('DD MMM YYYY')}
                                </div>
                                <div>
                                    {dayjs(c?.date).format('HH:mm[h]')}
                                </div>
                            </div>
                        )
                    },
                    type: {
                        data: c?.type,
                        dataComponent: (
                            <div className={cn(
                                c?.type === "Mail" ? "status-green-dark" : (c?.type === "InApp" ? "status-purple" : "status-red-dark"),
                                styles.status
                            )}>
                                {c?.type}
                            </div>
                        )
                    },
                    detail: {
                        data: 'detail',
                        dataComponent: (
                            <div>
                                <div style={{fontWeight: 'bold', color: 'black'}}>
                                    {c?.title?.filter(l => l?.active)?.[0]?.trans}
                                </div>
                                <p dangerouslySetInnerHTML={{__html: c?.textArea?.filter(l => l?.active)?.[0]?.trans}} className='line-clamp-1' style={{width: '100%'}} >
                                    
                                </p>
                            </div>
                        )
                    },
                    // delivered: {
                    //     data: '0',
                    //     dataComponent: '0'
                    // },
                    // read: {
                    //     data: '0',
                    //     dataComponent: '0'
                    // },
                    draft: {
                        data: 'draft',
                        dataComponent: (
                            <div>
                                {c?.state === 'Draft' ?(
                                    <div>
                                        <button disabled={loading} onClick={()=>{
                                            setNotificationSelected(c)
                                            setVisibleSend(true)
                                            
                                        }} className={cn("button button-small", styles.button)}>
                                            {loading ? 'Loading...' : <span>Send</span>}
                                        </button>
                                    </div>
                                ) : (
                                    <div style={{fontStyle: 'italic'}}>
                                        {c?.state}
                                    </div>
                                )}
                            </div>
                        )
                    },
                    actions: {
                        data: 'actions',
                        dataComponent: (
                            <div style={{ display: "flex", alignItems: "center"}}>
                                <BiShow onClick={()=>{
                                    setNotificationSelected(c)
                                    setVisiblePreview(true)
                                }} style={{fontSize: '18px', cursor: 'pointer'}} />
                                <BiTrash  onClick={()=>{
                                    setNotificationSelected(c)
                                    setVisibleDelete(true)
                                }} style={{marginLeft: '8px', fontSize: '18px', cursor: 'pointer', color: 'red'}} />
                                {(c?.type === "InApp" && c?.state === 'Draft') &&
                                    <BiEdit onClick={()=>history.push(`/notifications/edit/inapp/${c?._id}`)} style={{marginLeft: '8px', fontSize: '18px', cursor: 'pointer', color: 'blue'}} />
                                }
                                {(c?.type === "Push" && c?.state === 'Draft') &&
                                    <BiEdit onClick={()=>history.push(`/notifications/edit/push/${c?._id}`)} style={{marginLeft: '8px', fontSize: '18px', cursor: 'pointer', color: 'blue'}} />
                                }
                                {(c?.type === "Mail" && c?.state === 'Draft') &&
                                    <BiEdit onClick={()=>history.push(`/notifications/edit/email/${c?._id}`)} style={{marginLeft: '8px', fontSize: '18px', cursor: 'pointer', color: 'blue'}} />
                                }
                            </div>
                        )
                    }
                }
            }))
        }
    }

    useEffect(() => {
        handleGetNews()
    }, [])

    const handleSave = async () =>{
        console.log("notificationSelected", notificationSelected)
        const data = await deleteNews(notificationSelected)
        console.log("data", data)
        if(data?.data === true){
            handleGetNews()
            toast.success(`${notificationSelected?.title?.filter(l => l?.active)?.[0]?.trans} Deleted succesfully`)
            setVisibleDelete(false)
        }else{
            toast.error(`${data?.response?.data?.message}`)
        }
    }
    

    return (
        <div>
            <Modal visible={visibleSend} onClose={() => setVisibleSend(false)}>
                <div>
                    <b>Send</b>
                    <div style={{marginTop: 40, fontSize: 20}}>
                        Do you want to send <b>{notificationSelected?.title?.filter(l => l?.active)?.[0]?.trans}</b>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10}}>
                        <button style={{marginLeft: 20}} className={cn("button-stroke", styles.button)} onClick={()=>setVisibleSend(false)} >
                           <span>Cancel</span>
                        </button>
                        <button style={{marginLeft: 20}} disabled={loading} className={cn("button-stroke-blue", styles.button)} onClick={()=>{
                            
                            handleSendNew(notificationSelected)
                        }} >
                           {loading ? 'Loading...' : <span>Send</span>}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal visible={visibleDelete} onClose={() => setVisibleDelete(false)}>
                <div>
                    <b>Delete</b>
                    <div style={{marginTop: 40, fontSize: 20}}>
                        Do you want to delete <b>{notificationSelected?.title?.filter(l => l?.active)?.[0]?.trans}</b>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10}}>
                        <button style={{marginLeft: 20}} className={cn("button-stroke-red", styles.button)} onClick={handleSave} >
                           <span>Delete</span>
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal visible={visiblePreview} onClose={() => setVisiblePreview(false)}>
                <div>
                    <b>{notificationSelected?.title?.filter(l => l?.active)?.[0]?.trans}</b>
                    <div dangerouslySetInnerHTML={{__html: notificationSelected?.textArea?.filter(l => l?.active)?.[0]?.trans}} style={{marginTop: 40}}>
                    </div>
                </div>
            </Modal>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30}} className={cn(styles.nav, "tablet-hide")}>
                {navigation.map((x, index) => (
                    <button
                        className={cn(styles.link, {
                        [styles.active]: x === activeTab,
                        })}
                        onClick={() => setActiveTab(x)}
                        key={index}
                    >
                        {x}
                    </button>
                ))}
            </div>
            <TableComponent 
                title={activeTab}
                columns={columns}
                data={activeTab === 'All' ? dataTable : dataTable?.filter(c => c?.type?.data === activeTab)}
                isSearch={false}
                isFilter
                isButton
                buttonText={activeTab === 'All' || activeTab === 'Mail' ? "New email" : (activeTab === 'Push' ? 'New Push Notification' : 'New In-App Notification')}
                buttonIcon={<BiPlus />}
                buttonHandler={()=>activeTab === 'All' || activeTab === 'Mail' ? history.push('/notifications/add/email') : (activeTab === 'Push' ? history.push('/notifications/add/push') : history.push('/notifications/add/inapp'))}
            />
        </div>
    );
}

export default Notifications;